import _ from "lodash";
import React from "react";
import ReviewManagementDetailTableRow from "./components/ReviewManagementDetailTableRow";
import useDetailedView from "./hooks/useDetailedView";
import { VirtualTable } from "../../components/tables";
import { TableRecordCountDisplayFooter } from "../../components/tables/VirtualTable";
import { StandardTableSortablePropertyHeader } from "../../components/tables/StandardTable";
import { ErrorMessage, ErrorMessageWrapper } from "../../components/messages";
import { ThemedSpan } from "../../components/utilities";
import ReviewManagementAssignStaffModal from "../reviewmanagementeditmodal/components/ReviewManagementAssignStaffModal";
import { useEditReview } from "../reviewmanagementeditmodal/hooks/EditReviewContext";
import EditReviewModal from "../revieweditmodal/components/EditReviewModal";
import { useOverwriteReview } from "../revieweditmodal/hooks/OverwriteReviewContext";

const ReviewManagementDetailed = () => {
    const {
        clear: refreshTable,
        loadMore,
        isRowLoaded,
        sort,
        sortTable,
        results,
        totalCount,
        error,
        isNextPageLoading,
        isError,
    } = useDetailedView();

    const { clear, isLoading, isModalActive, review, saveChanges } = useEditReview();

    return (
        <>
            <ReviewManagementAssignStaffModal
              review={review}
              show={isModalActive}
              isLoading={isLoading}
              handleSave={(ops) => saveChanges(ops).then(clear)}
              handleClose={clear}
            />
            
            <VirtualTable
                enableScrollTracking={false}
                row={ReviewManagementDetailTableRow}
                header={
                    <thead>
                        <tr>
                            <StandardTableSortablePropertyHeader
                                property="masterAccountName"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                            >
                                Client
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="reviewAdviser"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                            >
                                Adviser
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="reviewDate"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                            >
                                Review Date
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="verificationScore"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                Valuation
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="preReviewDocumentScore"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                PRD Issued
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="appointmentScore"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                Scheduled
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="meetingNotesScore"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                Notes
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="followUpScore"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                Follow-up
                            </StandardTableSortablePropertyHeader>
                            <StandardTableSortablePropertyHeader
                                property="reviewStatus"
                                sort={sort}
                                sortTable={sortTable}
                                isSticky={true}
                                className="text-center"
                            >
                                On Hold
                            </StandardTableSortablePropertyHeader>
                            <th></th>
                        </tr>
                    </thead>
                }
                footer={
                    <TableRecordCountDisplayFooter 
                        totalCount={totalCount}
                        isNewRecordsDisplayed={false}
                        newRecordsSinceCache={0}
                        reload={refreshTable}
                    />
                }
                error={error}
                isItemLoaded={isRowLoaded}
                loadMoreItems={loadMore}
                itemSize={50}
                isLoading={isNextPageLoading}
                itemCount={totalCount}
                itemData={results}
            />
        </>
    );
};

export default ReviewManagementDetailed;
