
const { wealthProApi } = require("../../redux/api");

const validation = async ({ bizSheetId, instructionIds, dispatch, queryFulfilled, updatedStatus = null, updatedStatusDescription = null }) => {
    try {
        // Remove instruction and new money-level errors
        const removeInstructionsErrorsResults = instructionIds.map(instructionId =>
            dispatch(wealthProApi.util.updateQueryData("fetchInstruction", { instructionId }, (draft) => {
                draft.errors = null;
            }))
        );
        const removeNewMoneyErrorsResults = dispatch(wealthProApi.util.updateQueryData("fetchNewMoney", { bizSheetId }, (draft) => {
            draft.forEach(newMoney => newMoney.errors = null);
        }));
        const removeNewMoneyErrorsOnBizSheetResult = dispatch(wealthProApi.util.updateQueryData("fetchBizSheet", { bizSheetId }, (draft) => {
            draft.newMoney.forEach(newMoney => newMoney.errors = null);
        }));

        // Set status pending update, and make bizSheet read-only
        const updateSheetStatusResult = updatedStatus && dispatch(wealthProApi.util.updateQueryData("fetchBizSheet", { bizSheetId }, (draft) => {
            draft.status = updatedStatus;
            draft.statusDescription = updatedStatusDescription;
            draft.isReadOnly = true;
        }));

        try {
            // Wait for the request to finish then update the cache with any errors
            const { data: response } = await queryFulfilled;

            const { isValid, instructionResponses, newMoneyResponses } = response;

            if (isValid)
                return;

            Object.keys(instructionResponses).forEach(instructionId => {
                dispatch(wealthProApi.util.updateQueryData("fetchInstruction", { instructionId: instructionId.replaceAll(/-/g, "") }, (draft) => {
                    draft.errors = instructionResponses[instructionId]?.errors;
                }));
            });

            Object.keys(newMoneyResponses).forEach(newMoneyIndex => {
                dispatch(wealthProApi.util.updateQueryData("fetchNewMoney", { bizSheetId }, (draft) => {
                    draft[newMoneyIndex].errors = newMoneyResponses[newMoneyIndex]?.errors;
                }));
            });

            dispatch(wealthProApi.util.updateQueryData("fetchBizSheet", { bizSheetId }, (draft) => {
                Object.keys(newMoneyResponses).forEach(newMoneyIndex => {
                    draft.newMoney[newMoneyIndex].errors = newMoneyResponses[newMoneyIndex]?.errors;
                });
            }));

            // Reset the status if there were errors
            if (updatedStatus)
                updateSheetStatusResult.undo();
        } catch (error) {
            console.error("Error validating business sheet", error);

            removeInstructionsErrorsResults.forEach(patchResult => patchResult.undo());
            removeNewMoneyErrorsResults.undo();
            removeNewMoneyErrorsOnBizSheetResult.undo();

            if (updatedStatus)
                updateSheetStatusResult.undo();
        }

    } catch (error) {
        console.error("Error updating cache pre-validation", error);
    }
};

const businessSheetEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["bizSheetNewMoney"]
}).injectEndpoints({
    endpoints: (builder) => ({
        createBizSheet: builder.mutation({
            query: ({ data }) => ({
                url: `api/recommendations/businesssheet`,
                method: "POST",
                body: data
            })
        }),
        fetchBizSheetsForAccount: builder.query({
            query: ({ masterAccountId }) => `api/recommendations/BusinessSheet/Account/${masterAccountId}?listType=select`,
        }),
        searchBizSheets: builder.query({
            query: ({
                masterAccountId,
                searchTerm,
                page,
                limit
            }) => `api/recommendations/BusinessSheet/Search/Account/${masterAccountId}?searchTerm=${searchTerm}&page=${page}&limit=${limit}&fetchType=0`,
        }),
        fetchBizSheet: builder.query({
            query: ({ bizSheetId }) => `api/recommendations/BusinessSheet/${bizSheetId}`
        }),
        applyBizSheetCosts: builder.mutation({
            query: ({ bizSheetId }) => ({
                url: `api/recommendations/BusinessSheet/${bizSheetId}/ProductCosts/Apply`,
                method: "POST"
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data: instructions } = await queryFulfilled;

                    try {
                        var patches = [];
                        // Change every instruction's invest and (where appropriate) divest, to represent the updated product costs
                        instructions.forEach(instruction => {
                            patches.push(dispatch(wealthProApi.util.updateQueryData("fetchInstruction", { instructionId: instruction.id }, () => instruction)));

                            patches.push(dispatch(wealthProApi.util.updateQueryData("fetchInvest", { instructionId: instruction.id }, () => instruction.invest)));

                            if (instruction.divestId)
                                patches.push(dispatch(wealthProApi.util.updateQueryData("fetchDivest", { instructionId: instruction.id }, () => instruction.divest)));
                        });
                    } catch (error) {
                        console.error("Error updating cache", error);
                        patches.forEach(patchResult =>
                            patchResult.undo())
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }),
        getPdf: builder.query({
            query: ({ bizSheetId }) => `api/recommendations/BusinessSheet/${bizSheetId}/Pdf`,
            async onQueryStarted({ bizSheetId, instructionIds }, { dispatch, queryFulfilled }) {
                await validation({
                    bizSheetId,
                    instructionIds,
                    dispatch,
                    queryFulfilled
                });
            }
        }),
        // Note - This status is now "Compliance Locked", pending "Await Compliance" and further report status changes
        finaliseBizSheet: builder.mutation({
            query: ({ bizSheetId }) => ({
                url: `api/recommendations/BusinessSheet/${bizSheetId}/Finalise`,
                method: "PATCH"
            }),
            async onQueryStarted({ bizSheetId, instructionIds }, { dispatch, queryFulfilled }) {
                try {
                    await validation({
                        bizSheetId,
                        instructionIds,
                        dispatch,
                        queryFulfilled,
                        updatedStatus: 18,
                        updatedStatusDescription: "Compliance Locked"
                    });
                } catch (error) {
                    console.error("Error removing existing errors", error);

                }
            }
        }),
        makeBizSheetDraft: builder.mutation({
            query: ({ bizSheetId }) => ({
                url: `api/recommendations/BusinessSheet/${bizSheetId}/Draft`,
                method: "PATCH"
            }),
            async onQueryStarted({ bizSheetId }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    // Need to update statusDescription and readonly if the request is successful
                    dispatch(wealthProApi.util.updateQueryData("fetchBizSheet", { bizSheetId }, (draft) => {
                        draft.status = data.status;
                        draft.statusDescription = data.statusDescription;
                        draft.isReadOnly = false;
                    }));
                } catch (error) {
                    console.error("Error making business sheet draft", error);
                }
            }
        }),
        fetchNewMoney: builder.query({
            query: ({ bizSheetId }) => `api/recommendations/BusinessSheet/${bizSheetId}/NewMoney`,
            providesTags: (_result, _error, { bizSheetId }) => [{
                type: "bizSheetNewMoney",
                id: bizSheetId
            }]
        }),
        saveNewMoney: builder.mutation({
            query: ({ bizSheetId, updatedList }) => ({
                url: `api/recommendations/BusinessSheet/${bizSheetId}/NewMoney`,
                method: 'POST',
                body: { updatedList }
            }),
            async onQueryStarted({ bizSheetId, updatedList }, { dispatch, queryFulfilled }) {
                try {
                    // Wait for the request to finish then update the sum of the new money
                    await queryFulfilled;

                    dispatch(wealthProApi.util.updateQueryData("fetchBizSheet", { bizSheetId }, (draft) => {
                        draft.newMoneySum = updatedList.reduce((acc, curr) => acc + curr.investmentAmount, 0);
                        draft.newMoney = updatedList;
                    }));
                } catch (error) {
                    console.error(error, "Error saving New Money changes")
                }
            },
            invalidatesTags: (_result, _error, { bizSheetId }) => [{ type: "bizSheetNewMoney", id: bizSheetId }]
        }),
        sendToAdmin: builder.mutation({
            query: ({ bizSheetId }) => ({
                url: `api/recommendations/BusinessSheet/${bizSheetId}`,
                method: "POST"
            }),
            async onQueryStarted({ bizSheetId, instructionIds }, { dispatch, queryFulfilled }) {
                await validation({
                    bizSheetId,
                    instructionIds,
                    dispatch,
                    queryFulfilled,
                    updateStatus: true
                });
            }
        }),
        getBizSheetTestCertificate: builder.query({
            query: ({ bizSheetId, targetProfiles }) => ({
                url: `api/recommendations/BusinessSheet/${bizSheetId}/PortfolioTest`,
                method: "POST",
                body: targetProfiles
            })
        })
    })
});

export const {
    useFetchBizSheetsForAccountQuery,
    useFetchBizSheetQuery,
    useLazyFetchBizSheetQuery,
    useCreateBizSheetMutation,
    useLazySearchBizSheetsQuery,
    useApplyBizSheetCostsMutation,
    useLazyGetPdfQuery,
    useFinaliseBizSheetMutation,
    useMakeBizSheetDraftMutation,
    useFetchNewMoneyQuery,
    useSaveNewMoneyMutation,
    useSendToAdminMutation,
    useLazyGetBizSheetTestCertificateQuery
} = businessSheetEndpoints;