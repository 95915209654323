import { wealthProApi } from '../../redux/api';

const hoAdminApplicationTransactionApiEndpoints = wealthProApi
    .injectEndpoints({
        endpoints: (builder) => ({
            fetchPortfolioChanges: builder.query({
                query: ({ masterAccountId }) => `api/headofficeadmin/applicationtransaction/portfolio/changes/${masterAccountId}`
            }),
            fetchPortfolioChangesCount: builder.query({
                query: ({ masterAccountId }) => `api/headofficeadmin/applicationtransaction/portfolio/changes/count/${masterAccountId}`
            })
        })
    });

export const {
    useFetchPortfolioChangesQuery,
    useFetchPortfolioChangesCountQuery
} = hoAdminApplicationTransactionApiEndpoints;