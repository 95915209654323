import React, {useState} from "react";
import { NumericFormat } from "react-number-format";
import Button from "../../../components/buttons";
import { DateInput } from "../../../components/forms";
import { Dropdown } from "react-bootstrap";
import useValuationExport from "../hooks/useValuationExport";
import useTestCertificateExport from "../hooks/useTestCertificateExport";
import toast from "react-hot-toast";
import styled from "styled-components";
import moment from "moment";
import PortfolioValuationViewSelector from "./PortfolioViewSelector";
import PortfolioChangesModal from "./PortfolioChangesModal";

const ValuationExportToggle = React.forwardRef(({ ...rest }, ref) => {
    return (
        <div ref={ref} {...rest}>
            <Button className="w-100">Print PDF</Button>
        </div>
    );
});

const WarningListItem = styled.li`
    color:${props => props.colour};
    font-weight:bold;
`;

const PortfolioBreakdown = ({ data, portfolioChanges = [], currentView, setCurrentView }) => {

    const { masterAccountId } = data;

    const { exportValuationPdf } = useValuationExport(masterAccountId);
    const { exportTestCertificatePdf, isLoadingProfiles } = useTestCertificateExport(masterAccountId);

    const menuId = React.useId();
    const [exportDate, setExportDate] = React.useState(moment().format("YYYY-MM-DD"));
    
    const [showPortfolioChanges, setShowPortfolioChanges] = useState(false);
    const handleClosePortfolioChanges = () => setShowPortfolioChanges(false);

    if (
        data !== undefined &&
        data.designations != null &&
        data.designations.length === 0
    ) {
        return <h5>This account currently has no investment holdings.</h5>;
    }

    const exportValuation = (isBasic) => {
        toast.promise(exportValuationPdf(exportDate, isBasic), {
            loading: `Building the valuation export`,
            success: (data) => {
                var bytesPdf = Uint8Array.from(window.atob(data), c => c.charCodeAt(0))

                var file = new Blob([bytesPdf], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);

                window.open(fileURL, 'Valuation');
                return `Valuation is open in a new window`;
            },
            error: (err) => <div>Failed to build Valuation - {err?.data}</div>

        });
    };

    const exportTestCertificate = () => {
        toast.promise(exportTestCertificatePdf(exportDate), {
            loading: `Building the test certificate export`,
            success: (data) => {
                console.log(data);
                var bytesPdf = Uint8Array.from(window.atob(data), c => c.charCodeAt(0))

                var file = new Blob([bytesPdf], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);

                window.open(fileURL, 'Test Certificate');
                return `Test Certificate is open in a new window`;
            },
            error: (err) => <div>Failed to build Test Certificate - {err?.data}</div>
        });
    }


    return (
        <>
        <PortfolioChangesModal 
            show={showPortfolioChanges}
            handleClose={handleClosePortfolioChanges}
            portfolioChanges={portfolioChanges}
        />
        <div className="row">
            <div className="col-4">
                {data &&
                    data.designations &&
                    data.designations.map((el, i) => (
                        <div className="row mb-2" key={el.designationId}>
                            <div className="col">
                                <h6>
                                    <b>{el.designation.designation}</b>
                                </h6>
                            </div>
                            <div className="col" style={{ textAlign: "right" }}>
                                <NumericFormat
                                    value={el.designationValue.toFixed(2)}
                                    thousandSeparator={true}
                                    displayType="text"
                                    decimalScale={0}
                                    prefix={"£"}
                                    />
                            </div>
                        </div>
                    ))}
                {data && (
                    <div
                    className="row mb-2"
                    key={data.masterAccountId}
                        style={{
                            borderTop: "1px solid #80808040",
                        }}
                        >
                        <div className="col"></div>
                        <div className="col" style={{ textAlign: "right" }}>
                            <NumericFormat
                                value={data?.portfolioValue?.toFixed(2)}
                                thousandSeparator={true}
                                displayType="text"
                                decimalScale={0}
                                prefix={"£"}
                                />
                        </div>
                    </div>
                )}
            </div>
            <div className="col-1"></div>
            <div className="col-4">
                <div className="row mb-2">
                    {(data != null && data.warnings != null && data.warnings.length > 0 || (portfolioChanges != null && portfolioChanges.length > 0)) && 
                        <div className="col">
                            <h6>
                                <b>Warnings</b>
                            </h6>
                            <ul>
                                {data.warnings.map((el, i) => (
                                    <WarningListItem colour={el.colour} key={i}>{el.warningText}</WarningListItem>
                                ))}
                                {portfolioChanges.length > 0 &&
                                    <WarningListItem key={-1}><button className="btn btn-link p-0" onClick={() => {setShowPortfolioChanges(true)}}>There are some active changes to this portfolio</button></WarningListItem>
                                }
                            </ul>
                        </div>
                    }
                    {(data == null || data.warnings == null || data.warnings.length <= 0) && (portfolioChanges == null || portfolioChanges.length <= 0) &&
                        <div className="col">
                            <h6>
                                <b>There are no warnings for this portfolio.</b>
                            </h6>
                        </div>
                    }
                </div>
            </div>
            <div className="col-1"></div>
            <div className="col">
                <div className="row">
                    <h6>
                        <b>Select View</b>
                    </h6>
                   <PortfolioValuationViewSelector    
                       defaultValue={currentView}
                       onChange={({ value }) => setCurrentView(value)}
                       />
                </div>
                <div className="row mt-2">
                    <h6>
                        <b>Print Date</b>
                    </h6>
                    <div className="col">
                        <DateInput
                            value={exportDate}
                            onBlur={(value) => setExportDate(value)}
                            />
                    </div>
                    <div className="col">
                        <span className="mt-4">
                            <Dropdown className="caret-off">
                                <Dropdown.Toggle id={menuId} as={ValuationExportToggle} />
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => exportValuation(true)}>
                                        <span className="ms-1">Portfolio Valuation</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => exportValuation(false)}>
                                        <span className="ms-1">Portfolio Performance</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={exportTestCertificate} disabled={isLoadingProfiles}>
                                        <span className="ms-1">Test Certificate</span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </span>
                    </div>
                </div> 
            </div>
        </div>
    </>
    );
};

export default PortfolioBreakdown;
