import { useClientContext } from "../../../hooks/ClientContext";
import { useFetchAgreedVolatilityProfileSectionQuery } from "../../../services/clientfactfind";

const useAgreedVolatilityProfile = () => {
    const { id: masterAccountId } = useClientContext();
    const { data: profileSection, isLoading: isLoadingProfiles } = useFetchAgreedVolatilityProfileSectionQuery({ masterAccountId })

    return {
        isLoadingProfiles,
        profileSection
    };

}

export default useAgreedVolatilityProfile;