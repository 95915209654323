import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const icHouseRulesApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (build) => ({
        fetchClientProfiles: build.query({
            query: () => `api/assets/clientprofile`
        }),
        fetchGeneralRules: build.query({
            query: ({ clientProfileId }) => `api/assets/clientprofile/${clientProfileId}/general`
        }),
        patchGeneralRules: build.mutation({
            query: ({ clientProfileId, operations }) => ({
                url: `api/assets/clientprofile/${clientProfileId}/general`,
                method: "PATCH",
                body: operations
            })
        }),
        createClassificationRange: build.mutation({
            query: ({ clientProfileId }) => ({
                url: `api/assets/clientprofile/${clientProfileId}/classificationranges`,
                method: "POST"
            }),
            async onQueryStarted({ clientProfileId }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(wealthProApi.util.updateQueryData("fetchGeneralRules", { clientProfileId }, (draft) => {
                        draft.classificationRanges.push(data);
                    }));
                } catch (error) {
                    console.error("Error creating classification range", error);
                }
            }
        }),
        patchClassificationRange: build.mutation({
            query: ({ rangeId, operations }) => ({
                url: `api/assets/classificationrange/${rangeId}`,
                method: "PATCH",
                body: operations
            }),
            async onQueryStarted({ clientProfileId, rangeId, operations }, { dispatch, queryFulfilled }) {
                try {
                    const updateRangeResult = dispatch(wealthProApi.util.updateQueryData("fetchGeneralRules", { clientProfileId }, (draft) => {
                        applyPatch(draft.classificationRanges.find((el) => el.rangeId === rangeId), operations);
                    }));

                    try {
                        await queryFulfilled
                    } catch (error) {
                        console.error("Error patching classification range", error);
                        updateRangeResult.undo();
                    }
                } catch (error) {
                    console.error("Error updating query data", error);
                    throw error;
                }
            }
        }),
        deleteClassificationRange: build.mutation({
            query: ({ rangeId }) => ({
                url: `api/assets/classificationrange/${rangeId}`,
                method: "DELETE"
            }),
            async onQueryStarted({ clientProfileId, rangeId }, { dispatch, queryFulfilled }) {
                try {
                    const removeRangeResult = dispatch(wealthProApi.util.updateQueryData("fetchGeneralRules", { clientProfileId }, (draft) => {
                        draft.classificationRanges = draft.classificationRanges.filter((el) => el.rangeId !== rangeId);
                    }));

                    try {
                        await queryFulfilled
                    } catch (error) {
                        console.error("Error deleting classification range", error);
                        removeRangeResult.undo();
                    }
                } catch (error) {
                    console.error("Error updating query data", error);
                }
            }
        }),
        createClassGroup: build.mutation({
            query: ({ clientProfileId }) => ({
                url: `api/assets/clientprofile/${clientProfileId}/classgroups`,
                method: "POST"
            }),
            async onQueryStarted({ clientProfileId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: groupId } = await queryFulfilled

                    dispatch(wealthProApi.util.updateQueryData("fetchGeneralRules", { clientProfileId }, (draft) => {
                        draft.classGroupRules.push({ groupId, weighting: 1 });
                    }));
                    dispatch(wealthProApi.util.updateQueryData("fetchClassGroupsForSelect", { clientProfileId }, (draft) => {
                        draft.push({ label: "", value: groupId });
                    }))
                } catch (error) {
                    console.error("Error creating class group", error);
                }
            }
        }),
        patchClassGroupRule: build.mutation({
            query: ({ groupId, operations }) => ({
                url: `api/assets/classgrouprule/${groupId}`,
                method: "PATCH",
                body: operations
            }),
            async onQueryStarted({ clientProfileId, groupId, operations }, { dispatch, queryFulfilled }) {
                try {
                    const updateGroupResult = dispatch(wealthProApi.util.updateQueryData("fetchGeneralRules", { clientProfileId }, (draft) => {
                        applyPatch(draft.classGroupRules.find((el) => el.groupId === groupId), operations);
                    }));
                    const updateSelectResult = dispatch(wealthProApi.util.updateQueryData("fetchClassGroupsForSelect", { clientProfileId }, (draft) => {
                        if (operations.some(el => el.path.contains("name")))
                            applyPatch(draft.find((el) => el.value === groupId), operations);
                    }));

                    try {
                        await queryFulfilled
                    } catch (error) {
                        console.error("Error patching class group", error);
                        updateGroupResult.undo();
                        updateSelectResult.undo();
                    }
                } catch (error) {
                    console.error("Error updating query data", error);
                    throw error;
                }
            }
        }),
        deleteClassGroupRule: build.mutation({
            query: ({ groupId }) => ({
                url: `api/assets/classgrouprule/${groupId}`,
                method: "DELETE"
            }),
            async onQueryStarted({ clientProfileId, groupId }, { dispatch, queryFulfilled }) {
                try {
                    const removeGroupResult = dispatch(wealthProApi.util.updateQueryData("fetchGeneralRules", { clientProfileId }, (draft) => {
                        draft.classGroupRules = draft.classGroupRules.filter((el) => el.groupId !== groupId);
                    }));

                    try {
                        await queryFulfilled
                    } catch (error) {
                        console.error("Error deleting class group", error);
                        removeGroupResult.undo();
                    }
                } catch (error) {
                    console.error("Error updating query data", error);
                }
            }
        })
    })
});

export const {
    useFetchClientProfilesQuery,
    useLazyFetchClientProfilesQuery,
    useLazyFetchGeneralRulesQuery,
    usePatchGeneralRulesMutation,
    useCreateClassificationRangeMutation,
    usePatchClassificationRangeMutation,
    useDeleteClassificationRangeMutation,
    useCreateClassGroupMutation,
    usePatchClassGroupRuleMutation,
    useDeleteClassGroupRuleMutation
} = icHouseRulesApiEndpoints;