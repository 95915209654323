import React from "react";
import usePortfolioInvestmentsByVolatility from "../hooks/usePortfolioInvestmentsByVolatility";
import PortfolioWrapper from "./PortfolioWrapper";
import Skeleton from "react-loading-skeleton";
import { NumericFormat } from "react-number-format";
import { useClientContext } from "../../../hooks/ClientContext";
import { ViewPdfModal } from "../../../components/modals";
import useFundFactSheets from "../hooks/useFundFactSheets";

const PortfolioInvestmentsByVolatilityView = () => {

    const { id } = useClientContext();
    const { data = null, error = false, isFetching = false, isLoading = false } = usePortfolioInvestmentsByVolatility(id);

    const [{
        showPreview,
        hidePreview
    }, {
        pdfPreview,
        isGettingPdfPreview,
        shouldShowPdfPreview,
        getPdfPreviewError
    }] = useFundFactSheets();

    if(isLoading || isFetching){
        return(
            <PortfolioWrapper
             label={`Loading Volatilities...`}
            >
                <Skeleton />
            </PortfolioWrapper>
        );
    }

    if(error){
        return (
            <PortfolioWrapper>
                <div className="flex flex-col w-full text-center text-zinc-600">
                    <p>Some error here</p>
                </div>
            </PortfolioWrapper>
        );
    }

    return ( <>
            <ViewPdfModal
                title="Fact Sheet Preview"
                data={pdfPreview}
                show={shouldShowPdfPreview}
                handleClose={hidePreview}
                isLoading={isGettingPdfPreview}
                error={getPdfPreviewError}
                backdrop
            />
        {data != null && data.length > 0 && data.map((el, i) => (
            <div key={i} className="table-responsive">
                    <PortfolioWrapper 
                        key={`volatility-${i}`}
                        label={`${el.description} - £${new Intl.NumberFormat('en-GB').format(el.totalFundValues)} (${el.totalAllocation}%) `}
                        collapsedByDefault={true}>
                        <table className="table">
                            <thead>
                                <tr className="">
                                    <th className="md:w-[70%]">Investment</th>
                                    <th className="text-end">Value</th>
                                    <th className="text-end">Allocation</th>
                                    <th className="">Monitoring</th>
                                    <th className="">Opinion</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-x-auto border-top-0">
                                {el.subGroupings.length > 0 && el.subGroupings.map((c) => (
                                    <>
                                        <tr className="font-bold">
                                            <th colspan="5" className="md:w-[70%] truncate">{c.description}</th>
                                        </tr>

                                        {c.investments.length > 0 && c.investments.map((inv, index) => (
                                            <tr key={index}>
                                                {inv.assetGroupId != null &&
                                                <td>
                                                    <button className="btn btn-link p-0"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            showPreview(inv.assetGroupId);}
                                                            }>
                                                        {inv.investmentName}
                                                    </button>
                                                </td>
                                                }
                                                {inv.assetGroupId == null && <td>{inv.investmentName}</td>}
                                                <td className="text-end tabular-nums"> 
                                                     <NumericFormat
                                                        value={inv.value}
                                                        thousandSeparator={true}
                                                        decimalScale={0}
                                                        displayType="text"
                                                        prefix={"£"}
                                                    />
                                                </td>
                                                <td className="text-end">{inv.allocation}%</td>
                                                <td className="">{inv.monitoring}</td>
                                                <td className="">{inv.opinion}</td>
                                            </tr>
                                        ))}

                                        <tr className="border-bottom-0">
                                            <td className="border-bottom-0"></td>
                                            <th className="font-weight-bold text-end border-bottom-0"> 
                                                <NumericFormat
                                                    value={c.totalFundValues}
                                                    thousandSeparator={true}
                                                    decimalScale={0}
                                                    displayType="text"
                                                    prefix={"£"}
                                                />
                                            </th>
                                            <th className="font-weight-bold text-end border-bottom-0">{c.totalAllocation}%</th>
                                            <td colspan="2" className="border-bottom-0"></td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </PortfolioWrapper>
                </div>
            ))}
            </>
);

}

export default PortfolioInvestmentsByVolatilityView;