import { useEffect, useState } from "react";
import { useLazyFetchFactSheetPdfQuery } from "../../../services/assets";
import toast from "react-hot-toast";

const useFundFactSheets = () => {

    const [getPdfPreviewTrigger, { isLoading: isGettingPdfPreview, isError: getPdfPreviewIsError, error: getPdfPreviewError }] = useLazyFetchFactSheetPdfQuery();

    const [pdfPreview, setPdfPreview] = useState(null);
    const [shouldShowPdfPreview, setShouldShowPdfPreview] = useState(false);

    const showPreview = (assetGroupId) => {
        toast.promise(getPdfPreviewTrigger({ assetGroupId, draft : false }, false).unwrap(), {
            loading: `Attempting to open factsheet...`,
            success: (data) => {
                setPdfPreview(data.data);
                setShouldShowPdfPreview(true);
                
                return `Success!`;
            },
            error: (err) => <div>{err?.data?.message}</div>,
        });
    };

    const showDraftPreview = () => showPreview(true);

    const hidePreview = () => {
        setShouldShowPdfPreview(false);
    };

    useEffect(() => {
        if (!shouldShowPdfPreview)
            setPdfPreview(null)
    }, [shouldShowPdfPreview])

    return [{
        showPreview,
        showDraftPreview,
        hidePreview
    }, {
        pdfPreview,
        isGettingPdfPreview,
        shouldShowPdfPreview,
        getPdfPreviewIsError,
        getPdfPreviewError,
    }]

}

export default useFundFactSheets;