import React from "react";
import usePortfolioHistoricalReturns from "../hooks/usePortfolioHistoricalReturns";
import PortfolioWrapper from "./PortfolioWrapper";
import Skeleton from "react-loading-skeleton";
import { NumericFormat } from "react-number-format";
import { useClientContext } from "../../../hooks/ClientContext";
import { ThemedSpan } from "../../../components/utilities";
import { ViewPdfModal } from "../../../components/modals";
import useFundFactSheets from "../hooks/useFundFactSheets";

const PortfolioHistoricTotalReturnsView = () => {

    const { id } = useClientContext();

    const [{
        showPreview,
        hidePreview
    }, {
        pdfPreview,
        isGettingPdfPreview,
        shouldShowPdfPreview,
        getPdfPreviewError
    }] = useFundFactSheets();

    const { data = null, error = false, isFetching = false, isLoading = false } = usePortfolioHistoricalReturns(id);

    if(isLoading || isFetching){
        return(
            <PortfolioWrapper label={"Loading Historical Total Returns..."}>
                <Skeleton count={4}/>
            </PortfolioWrapper>
        );
    }

    if(error){
        return (
            <PortfolioWrapper label={"Historical Total Returns"}>
                <div className="flex flex-col w-full text-center text-zinc-600">
                    <p>Some error here</p>
                </div>
            </PortfolioWrapper>
        );
    }

    return ( 
    <>
    
        <ViewPdfModal
            title="Fact Sheet Preview"
            data={pdfPreview}
            show={shouldShowPdfPreview}
            handleClose={hidePreview}
            isLoading={isGettingPdfPreview}
            error={getPdfPreviewError}
            backdrop
        />
        <PortfolioWrapper label={"Historical Total Returns"}>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Investment</th>
                            <th className="text-end">Value</th>
                            <th className="text-end">1M</th>
                            <th className="text-end">3M</th>
                            <th className="text-end">6M</th>
                            <th className="text-end">1Y</th>
                            <th className="text-end">3Y(PA)</th>
                            <th className="text-end">5Y(PA)</th>
                        </tr>
                    </thead>
                    <tbody className="overflow-x-auto border-top-0">
                        {data.investments.length > 0 && data.investments.map((inv, index) => (
                            <tr key={index}>
                                 {inv.assetGroupId != null &&
                                    <td>
                                        <button className="btn btn-link p-0"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                showPreview(inv.assetGroupId);}
                                                }>
                                            {inv.investmentName}
                                        </button>
                                    </td>
                                }
                            {inv.assetGroupId == null && <td>{inv.investmentName}</td>}
                                <td className="text-end"> 
                                        <NumericFormat
                                            value={inv.value}
                                            thousandSeparator={true}
                                            decimalScale={0}
                                            displayType="text"
                                            prefix={"£"}
                                            />
                                </td>
                                <td className="text-end">
                                    <ThemedSpan variant={inv.totalReturn1m < 0 ? 'danger' : 'success'}>
                                        <NumericFormat
                                            value={inv.totalReturn1m}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            displayType="text"
                                            suffix={"%"}
                                            />
                                    </ThemedSpan>
                                </td>
                                <td className="text-end">
                                <ThemedSpan variant={inv.totalReturn3m < 0 ? 'danger' : 'success'}>
                                    <NumericFormat
                                        value={inv.totalReturn3m}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        displayType="text"
                                        suffix={"%"}
                                        />
                                    </ThemedSpan>
                                </td>
                                <td className="text-end">
                                <ThemedSpan variant={inv.totalReturn6m < 0 ? 'danger' : 'success'}>
                                    <NumericFormat
                                        value={inv.totalReturn6m}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        displayType="text"
                                        suffix={"%"}
                                        />
                                    </ThemedSpan>
                                </td>
                                <td className="text-end">
                                <ThemedSpan variant={inv.totalReturn12m < 0 ? 'danger' : 'success'}>
                                    <NumericFormat
                                        value={inv.totalReturn12m}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        displayType="text"
                                        suffix={"%"}
                                        />
                                    </ThemedSpan>
                                </td>
                                <td className="text-end">
                                <ThemedSpan variant={inv.averageTotalReturn3Year < 0 ? 'danger' : 'success'}>
                                    <NumericFormat
                                        value={inv.averageTotalReturn3Year}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        displayType="text"
                                        suffix={"%"}
                                        />
                                    </ThemedSpan>
                                </td>
                                <td className="text-end">
                                <ThemedSpan variant={inv.averageTotalReturn5Year < 0 ? 'danger' : 'success'}>
                                    <NumericFormat
                                        value={inv.averageTotalReturn5Year}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        displayType="text"
                                        suffix={"%"}
                                        />
                                    </ThemedSpan>
                                </td>
                            </tr>
                        ))}
                        {data == null || data.investments.length <= 0 && 
                            <tr>
                                <td>There are no investments to show.</td>
                            </tr>
                        }     
                    </tbody>
                </table>
            </div>
    </PortfolioWrapper>
    </>
);

}

export default PortfolioHistoricTotalReturnsView;