import { Modal } from "react-bootstrap";
import { Button } from "../../../components";

const PortfolioChangesModal = ({
    show = false,
    handleClose,
    portfolioChanges = []
}) => {

    const handleCancel = () => {
        handleClose();
    }

    return <Modal centered show={show} backdrop="static" size="xl">
    <Modal.Header>
        <Modal.Title>Ongoing Transactions</Modal.Title>
    </Modal.Header>
            <Modal.Body className="container-fluid">
                <div className="row mb-3 table-responsive p-3">
                <table className="table p-3">
                <thead>
                    <tr>
                        <th className="p-3" scope="col">Owner</th>
                        <th className="p-3" scope="col">Product</th>
                        <th className="p-3" scope="col">Provider</th>
                        <th className="p-3" scope="col">Operation</th>
                        <th className="p-3" scope="col">Fund Name</th>
                        <th className="p-3" scope="col">Traded</th>
                        <th className="p-3" scope="col">Status</th>
                    </tr>
                </thead>
                <tbody className="overflow-x-auto border-top-0">
                    {/* {isLoading && (
                        <tr className="p-2">
                            <td colSpan={7} className="align-middle">
                                <LoadingSpinner label="Checking for changes in your Portfolio..." />
                            </td>
                        </tr>
                    )}
                    {!isLoading && data && data.length === 0 && (
                        <tr className="p-2">
                            <td colSpan={7} className="text-slate-500">
                                <p className="mb-0">There are no changes active on your Portfolio.</p>
                            </td>
                        </tr>
                    )} */}
                    {portfolioChanges && portfolioChanges.map((row, i) => (
                        <tr key={i} className="p-2">
                            <td>{row.designationName}</td>
                            <td>{row.productName}</td>
                            <td className={`${row.providerName == null ? 'text-center' : ''}`}>{(row.providerName) ? row.providerName : "-"}</td>
                            <td>{row.transactionName}</td>
                            <td>{row.assetName}</td>
                            <td>{row.traded}</td>
                            <td>{row.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
                </div>
            </Modal.Body>
    <Modal.Footer>
        <Button variant="light" onClick={handleCancel}>
            Close
        </Button>
    </Modal.Footer>
</Modal>
}

export default PortfolioChangesModal;