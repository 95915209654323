import { FilterProvider, InvestmentTransactionDefaultFilters, useFilterContext } from "../../hooks/FilterContext";
import { useClientContext } from "../../hooks/ClientContext";
import ClientTransactions from "../../features/clienttransactions";

const ClientTransactionsPage = () => {

    const { id } = useClientContext();

    return (
        <FilterProvider
            filterStore={"transactions."+id}
            defaultFilters={InvestmentTransactionDefaultFilters}>
                <ClientTransactions />
        </FilterProvider>
    )
}

export default ClientTransactionsPage;