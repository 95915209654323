import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useCallback, useEffect, useId, useMemo, useState } from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { onTabPressSelect } from "../../helpers/select";
import FormGroup from "./FormGroup";
import FormInputGroup from "./FormInputGroup";
import FormLabel from "./FormLabel";
import InputErrorMessage from "./InputErrorMessage";

/**
 * Function to calculate the className property for a React Select
 * @param {*} className 
 */
export const CalculateClassName = (className, isSuccess, error, baseClassName = "react-select") => {
    let name = baseClassName;

    if (className) {
        name += ` ${className}`;
    }

    if (isSuccess === true) {
        name += ` is-valid`;
    }

    if (error) {
        name += ` is-invalid`;
    }

    return name;
};

/**
 * Default styles config for React Select components
 */
export const CalculateSelectStyles = (error) => {
    return {
        container: base => ({
            ...base,
            flex: 1,
        }),
        control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused ? error ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)' : '0 0 0 0.25rem rgba(13, 110, 253, 0.25)' : 'none',
            borderColor: state.isFocused ? (error ? '#dc3545' : '#86b7fe') : (state.selectProps.isValid ? '#198754' : (error ? '#dc3545' : '#ced4da')),
            '&:hover': {
                borderColor: state.isFocused ? (error ? '#dc3545' : '#86b7fe') : (error ? '#dc3545' : '#ced4da'),
            },
            minHeight: 36
        }),
        dropdownIndicator: (base, state) => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0
        }),
        menu: base => ({
            ...base,
            zIndex: 5
        }),
        placeholder: (base) => ({
            ...base,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        }),
    };
};

/**
 * Custom LoadingIndicator to show the tick on successful blur
 * @param {*} param0 
 * @returns 
 */
export const LoadingOrSuccessIndicator = ({ ...props }) => {
    if (props.selectProps.isValid) {
        if (props.selectProps.experimental === true) {
            return (
                <components.LoadingIndicator {...props}>
                    <FontAwesomeIcon className="pe-2" size="lg" icon="check" color="#198754" {...props} />
                </components.LoadingIndicator>
            );
        }
        else {
            return (
                <FontAwesomeIcon className="pe-2" size="lg" icon="check" color="#198754" {...props} />
            );
        }
    }
    else {
        return (
            <components.LoadingIndicator {...props} />
        );
    }
}

const FormSelect = React.forwardRef(({
    disableAnimations = false,
    className,
    components = {},
    defaultValue,
    defaultValueObject,
    experimental = false,
    horizontal = false,
    isCreateable = false,
    id,
    isLoadingOptions = false,
    isMulti = false,
    label,
    maxLimit = null,
    onBlur,
    onChange,
    options = [],
    successTimeout = 1000,
    errorMessage = null,
    errorAllowRetry = true,
    ...rest
}, ref) => {
    const defaultComponentId = useId();
    const componentId = id || defaultComponentId;
    const [error, setError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [creatableOptions, setCreateableOptions] = useState([]);

    const currentClassName = useMemo(() => CalculateClassName(className, isSuccess, error), [className, error, isSuccess])
    const currentStyles = useMemo(() => CalculateSelectStyles(error), [error]);

    const getDefaultValue = useCallback(() => {
        // allow for supplying a { label, value } object or array
        // handy for supplying value to Creatable where it isn't an option yet 
        if (defaultValueObject) {
            return defaultValueObject;
        }

        let flatOptions = options.map(({ options: opt, ...rest }) => opt && Array.isArray(opt) ? opt.map((el) => el) : rest);
        let fullOptions = _.flattenDeep(flatOptions);
        let filterableOptions = [...fullOptions, ...creatableOptions];

        if (isMulti === true && Array.isArray(defaultValue)) {
            return filterableOptions.filter(({ value: v }) => defaultValue.includes(v))
        }
        else if (isMulti === true && !defaultValue) {
            return [];
        }

        // NOTE: If no option is found this needs to return null not undefined from `.find`
        // as undefined causes it to get stuck on a previous value. 
        return filterableOptions.find(({ value: v }) => v === defaultValue) ?? null;
    }, [defaultValue, defaultValueObject, isMulti, options, creatableOptions]);

    const [currentValue, setCurrentValue] = useState(getDefaultValue());
    const [originalValue, setOriginalValue] = useState(getDefaultValue());

    const hasValueChanged = () => {
        if (isMulti === true) {
            var originalMap = originalValue.map(el => el.value);
            var currentMap = currentValue.map(el => el.value);

            return !originalMap.equals(currentMap);
        }

        return !_.isEqual(currentValue, originalValue);
        //return currentValue.value !== originalValue.value;
    };

    const onBlurEvent = (e) => {
        // check if we have a prevent default method we need to stop
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        // check that the value has changed before triggering anything
        // NOTE: also check the function HAS been passed into this component
        if (hasValueChanged() === false || !onBlur || typeof (onBlur) !== 'function') {
            return;
        }

        // trigger the loading animation on the input
        if (!disableAnimations) {
            setError(_ => null);
            setIsLoading(_ => true);
        }

        var possibleResult = onBlur(currentValue, e);   // used to safely check the function returns something we can check on

        // we can now safely use the `then` method and handle the response accordingly
        Promise.resolve(possibleResult).then(
            _ => {
                setOriginalValue(currentValue);

                if (disableAnimations)
                    return;

                setIsLoading(_ => false);
                setIsSuccess(_ => true);
            },
            error => {
                if (disableAnimations)
                    return;

                setIsLoading(_ => false);
                setError(_ => error);
            }
        );
    };

    const onChangeEvent = (e) => {
        setCurrentValue(e);

        if (isMulti === true && Array.isArray(e) && e.some(x => x['__isNew__'] === true)) {
            setCreateableOptions(e.filter(x => x['__isNew__'] === true));
        }

        if (isMulti !== true && e && e['__isNew__'] === true) {
            setCreateableOptions(original => [...original, e]);
        }
        if (onChange && typeof (onChange) === 'function') {
            onChange(e);
        }
    };

    useEffect(() => {
        if (errorMessage) {
            setError(_ => errorMessage);
        }
        else {
            setError(_ => null);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (isSuccess === false) {
            return;
        }

        let t = setTimeout(() => {
            setIsSuccess(_ => false);
        }, successTimeout);

        return () => clearTimeout(t);
    }, [isSuccess, successTimeout]);

    useEffect(() => {
        if (!_.isEqual(defaultValue ?? defaultValueObject, currentValue)) {
            setCurrentValue(getDefaultValue());
            setOriginalValue(getDefaultValue());
        }
    }, [defaultValue, defaultValueObject, getDefaultValue, isMulti]);

    return <FormGroup horizontal={horizontal}>
        {label && <FormLabel htmlFor={componentId} horizontal={horizontal}>{label}</FormLabel>}
        <FormInputGroup className={"has-error"} horizontal={horizontal} hasLabel={label ? true : false}>
            {isCreateable && (
                <CreatableSelect
                    id={componentId}
                    styles={currentStyles}
                    className={currentClassName}
                    classNamePrefix="Select"
                    components={{ LoadingIndicator: LoadingOrSuccessIndicator, ...components }}
                    menuPosition={'fixed'}
                    options={options}
                    value={currentValue}
                    isMulti={isMulti}
                    // isLoading has to be true when isSuccess is true to show the tick on success
                    isLoading={isLoadingOptions || isLoading === true || isSuccess}
                    isValid={isSuccess}
                    closeMenuOnSelect={!isMulti}
                    onBlur={onBlurEvent}
                    onChange={onChangeEvent}
                    experimental={experimental}
                    //isOptionDisabled={() => isMulti && maxLimit ? currentValue.length < maxLimit : false}
                    tabSelectsValue={false}
                    onKeyDown={(e) => e.key === "Tab" && onTabPressSelect(e)}
                    {...rest}
                />
            )}
            {!isCreateable && (
                <Select
                    id={componentId}
                    ref={ref}
                    styles={currentStyles}
                    className={currentClassName}
                    classNamePrefix="Select"
                    components={{ LoadingIndicator: LoadingOrSuccessIndicator, ...components }}
                    menuPosition={'fixed'}
                    options={options}
                    value={currentValue}
                    isMulti={isMulti}
                    // isLoading has to be true when isSuccess is true to show the tick on success
                    isLoading={isLoadingOptions || isLoading === true || isSuccess}
                    isValid={isSuccess}
                    closeMenuOnSelect={!isMulti}
                    onBlur={onBlurEvent}
                    onChange={onChangeEvent}
                    experimental={experimental}
                    //isOptionDisabled={() => isMulti && maxLimit ? currentValue.length >= maxLimit : false}
                    tabSelectsValue={false}
                    onKeyDown={(e) => e.key === "Tab" && onTabPressSelect(e)}
                    {...rest}
                />
            )}
            <InputErrorMessage error={error} retryCallback={onBlurEvent} allowRetry={errorAllowRetry} />
        </FormInputGroup>
    </FormGroup>
});

export default FormSelect;