import { useCallback, useState } from "react";
import { useCreateAssetMappingMutation, useDeleteAssetMappingMutation, useLazySearchAssetsQuery } from "../../../services/assets";

const useSearchFundAssetBySedol = () => {
    const [sedolSearchTrigger] = useLazySearchAssetsQuery();
    const [createMappingTrigger] = useCreateAssetMappingMutation();
    const [deleteMappingTrigger] = useDeleteAssetMappingMutation();

    const [errorMessage, setErrorMessage] = useState(null);

    const deleteMapping = useCallback((assetGroupId) => {
        return new Promise((resolve, reject) => {
            deleteMappingTrigger({ assetGroupId }).unwrap()
                .then(resolve, reject);
        });
    }, [deleteMappingTrigger]);

    const searchAndCreateMapping = useCallback((input, assetGroupId) => {
        setErrorMessage(null);
        return new Promise((resolve, reject) => {
            sedolSearchTrigger({ searchTerm: input }).unwrap()
                .then(
                    (res) => {
                        if (res?.length > 0) {
                            // Need to create a mapping between the SEDOL and the asset if possible, and return an error if not
                            createMappingTrigger({ assetGroupId: assetGroupId, assetId: res[0].assetId }).unwrap()
                                .then(() => resolve(res[0]),
                                    (err) => {
                                        if (err?.status === 409) {
                                            console.error(err);
                                            // Mapping already exists, reject and set the error message
                                            setErrorMessage(err?.data?.message)
                                        }
                                        reject();
                                    });
                        } else {
                            // No asset was found, but the sedol has changed, so remove the old mapping
                            return deleteMapping(assetGroupId).finally(() =>
                                resolve({ sedol: input, longAssetName: "Code not recognised" }));
                        }
                    },
                    _ => resolve({ sedol: input, longAssetName: "Code not recognised" }));
        });
    }, [createMappingTrigger, deleteMapping, sedolSearchTrigger]);

    return [{ searchAndCreateMapping, deleteMapping }, { errorMessage }];
}

export default useSearchFundAssetBySedol;