import _ from "lodash";
import { useEffect, useState } from "react";
import { InputGroup, Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { CurrencyInput, FormCheck, FormLabel, FormSelect } from "../../../components/forms";
import FormGroup from "../../../components/forms/FormGroup";
import { ClientProfileSelect, MasterAccountSelect, ServiceAgreementsSelect } from "../../../components/forms/selects";
import { useFilterContext } from "../../../hooks/FilterContext";

const searchScores = [
    { label: "Below Target", value: 0 },
    { label: "On Target", value: 1 },
    { label: "Above Target", value: 2 }
];

export const flaggedPortfolioStatuses = [
    { label: "Acceptable", uncheckedLabel: "Clear Flag", value: 0, colour: "green" },
    { label: "Action At Review", value: 1, colour: "orange" },
    { label: "Urgent Action Required", uncheckedLabel: "Set as Urgent", value: 2, colour: "red" },
];

export const PortfolioTestResultFiltersModal = ({
    handleClose,
    onFiltersChanged,
    show,
    ...rest
}) => {
    const { defaultFilters, filter, setFilter } = useFilterContext();
    const [tempFilters, setTempFilters] = useState(filter);

    const onClose = () => {
        if (handleClose && typeof handleClose === "function") {
            handleClose();
        }
    };

    const onApplyFilters = () => {
        setFilter((oldFilters) => ({
            ...oldFilters,
            ...tempFilters
        }));
        onClose();
    }

    const onClearFilters = () => {
        setTempFilters(defaultFilters);
    };

    const onFilterValueChanged = (property, value) => {
        setTempFilters((oldFilters) => ({
            ...oldFilters,
            [property]: value
        }));
    };

    useEffect(() => {
        setTempFilters((oldTemp) => !_.isEqual(oldTemp, filter)
            ? filter
            : oldTemp);
    }, [filter]);

    return <Modal size="xl" centered backdrop="static" show={show} onHide={handleClose}>
        <Modal.Header closeButton>Portfolio Test Result Filters</Modal.Header>
        <Modal.Body {...rest}>
            <PortfolioTestResultFilters
                filter={tempFilters}
                setFilter={onFilterValueChanged} />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="warning" onClick={onClearFilters} disabled={_.isEqual(tempFilters, defaultFilters)}>
                Reset Filters
            </Button>
            <Button variant="success" onClick={onApplyFilters}>
                Apply Filters
            </Button>
            <Button variant="light" onClick={onClose}>
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>;
}

const PortfolioValueRangeInput = ({ min, max, setMin, setMax }) => {
    return <FormGroup>
        <FormLabel>Portfolio Value Range</FormLabel>
        <div className="row">
            <CurrencyInput
                className="col pe-0"
                placeholder="Min"
                value={min ?? undefined}
                onChange={(_, { floatValue }) => setMin(floatValue)}
                decimalScale={0}
                fixedDecimalScale
                disableAnimations
            />
            <InputGroup.Text className="col-auto rounded-0">To</InputGroup.Text>
            <CurrencyInput
                className="col ps-0"
                inputClassName="rounded-0"
                placeholder="Max"
                value={max ?? undefined}
                onChange={(_, { floatValue }) => setMax(floatValue)}
                decimalScale={0}
                fixedDecimalScale
                disableAnimations
            />
        </div>
    </FormGroup>
}

const PortfolioTestResultFilters = ({ filter, setFilter }) => {
    const {
        masterAccountIds,
        serviceLevelAgreementIds,
        noServiceTerms,
        minPortfolioValue,
        maxPortfolioValue,
        targetProfileIds,
        reviewClientsOnly,
        volatilityScoreStatus,
        actualProfileIds,
        failedVolatilityTest,
        includeSlightFailVolatilityTest,
        flaggedStatuses,
        excludeFlagged,
        excludeUnflagged
    } = filter;
    const selectedServiceLevelAgreementIds = noServiceTerms ? [null, ...serviceLevelAgreementIds] : serviceLevelAgreementIds;

    return <>
        <div className="row gy-2">
            <div className="col-7">
                <MasterAccountSelect
                    label="Master Account(s)"
                    isMulti
                    value={masterAccountIds}
                    setValue={(selections) => setFilter("masterAccountIds", selections.map(({ value }) => value))}
                    disableAnimations
                />
            </div>
            <div className="col-5">
                <PortfolioValueRangeInput
                    min={minPortfolioValue}
                    max={maxPortfolioValue}
                    setMin={(value) => setFilter("minPortfolioValue", value)}
                    setMax={(value) => setFilter("maxPortfolioValue", value)}
                />
            </div>
            <div className="col-12 col-lg-4">
                <FormSelect
                    label="Volatility Score"
                    defaultValue={volatilityScoreStatus}
                    options={searchScores}
                    onChange={(selection) => setFilter("volatilityScoreStatus", selection?.value)}
                    isClearable
                    disableAnimations
                />
            </div>
            <div className="col-6 col-lg-4">
                <ClientProfileSelect
                    label="Target Profile(s)"
                    isMulti
                    value={targetProfileIds}
                    setValue={(selections) => setFilter("targetProfileIds", selections.map(({ value }) => value))}
                    disableAnimations
                />
            </div>
            <div className="col-6 col-lg-4">
                <ClientProfileSelect
                    label="Actual Profile(s)"
                    isMulti
                    value={actualProfileIds}
                    setValue={(selections) => setFilter("actualProfileIds", selections.map(({ value }) => value))}
                    disableAnimations
                />
            </div>
            <div className="col-12 col-lg-6">
                <FormSelect
                    label="Flagged Status"
                    defaultValue={flaggedStatuses}
                    options={flaggedPortfolioStatuses}
                    isMulti
                    onChange={(selections) => {
                        const values = selections.map(({ value }) => value);

                        setFilter("flaggedStatuses", values);

                        // Clear flag exclusion filters based on the new selection
                        if (values.includes(0))
                            setFilter("excludeUnflagged", false);
                        if (values.includes(1) || values.includes(2))
                            setFilter("excludeFlagged", false);
                    }}
                    disableAnimations
                />
            </div>
            <div className="col-6 col-lg-3 order-lg-1">
                <FormCheck
                    groupClassName="mb-2"
                    label="Unflagged Clients Only"
                    isChecked={excludeFlagged}
                    onChange={() => {
                        setFilter("excludeFlagged", !excludeFlagged);

                        // Clear the Flagged Only filter if we are setting this to true (and it is set)
                        setFilter("excludeUnflagged", excludeFlagged && excludeUnflagged);

                        if (!excludeFlagged)
                            setFilter("flaggedStatuses", flaggedStatuses.filter(val => val !== 0));
                    }}
                    disableAnimations
                />
            </div>
            <div className="col-6 col-lg-3 order-lg-1">
                <FormCheck
                    groupClassName="mb-2"
                    label="Flagged Clients Only"
                    isChecked={excludeUnflagged}
                    onChange={() => {
                        setFilter("excludeUnflagged", !excludeUnflagged);

                        // Clear the Unflagged Only filter if we are setting this to true (and it is set)
                        setFilter("excludeFlagged", excludeUnflagged && excludeFlagged);

                        if (!excludeUnflagged)
                            setFilter("flaggedStatuses", flaggedStatuses.filter(val => val === 0));
                    }}
                    disableAnimations
                />
            </div>
            <div className="col-12 col-lg-6">
                <ServiceAgreementsSelect
                    label="Service Level Agreement(s)"
                    isMulti
                    defaultValue={selectedServiceLevelAgreementIds}
                    onChange={(selections) => {
                        // Map to Ids and filter out "None" option (value: null)
                        const withServiceTerms = selections
                            .map(({ value }) => value)
                            .filter(val => val !== null);

                        setFilter("serviceLevelAgreementIds", withServiceTerms);

                        // "NoServiceTerms" is true when the new array is smaller than the old one (i.e. "None" was removed)
                        setFilter("noServiceTerms", withServiceTerms.length < selections.length);

                        // Clear the review clients only filter if only 'None' is selected
                        if (withServiceTerms.length === 0 && selections.length !== 0)
                            setFilter("reviewClientsOnly", false);
                    }}
                    includeNoneOption
                    disableAnimations
                />
            </div>
            <div className="col-12 col-lg-6 order-lg-1">
                <FormCheck
                    label="Review Clients Only"
                    isChecked={reviewClientsOnly}
                    onChange={() => setFilter("reviewClientsOnly", !reviewClientsOnly)}
                    disabled={serviceLevelAgreementIds.length === 0 && noServiceTerms}
                    disableAnimations
                />
            </div>
            <div className="col-6 col-lg-3 order-lg-1">
                <FormCheck
                    label="Failed Volatility Test"
                    isChecked={failedVolatilityTest}
                    onChange={() => setFilter("failedVolatilityTest", !failedVolatilityTest)}
                    disableAnimations
                />
            </div>
            <div className="col order-lg-1">
                <FormCheck
                    label="Include Slightly Failed Tests"
                    isChecked={includeSlightFailVolatilityTest}
                    onChange={() => setFilter("includeSlightFailVolatilityTest", !includeSlightFailVolatilityTest)}
                    disableAnimations
                />
            </div>
        </div>
    </>
}

export default PortfolioTestResultFilters;