import React from "react";
import {
    FormTextArea,
    FormSelect,
    FormLabel,
    RichTextEditor,
} from "../../../components/forms";
import { ConfirmModal } from "../../../components/modals";


const ReviewConclusion = ({ review, onFieldChanged }) => {

    const [showConfirmModal, setShowConfirmModal] = React.useState(false);

    return (
        <React.Fragment>
            <ConfirmModal
                title={"Meeting Notes"}
                message={"Please confirm if the meeting note is complete."}
                confirmButtonLabel="Yes"
                cancelButtonLabel="No"
                handleClose={() => setShowConfirmModal(false)}
                handleConfirm={() => {
                    
                    onFieldChanged("meetingNoteStatus", 2);
                    setShowConfirmModal(false);
                }}
                confirmButtonVariant="success"
                show={showConfirmModal}
            />
            {review.meetingNoteRequired && (
                <>
                    <div className="row mb-2 mt-4 bold">
                        <FormLabel className="col-2">
                            Confirmed Suitability at Review
                        </FormLabel>
                        <div className="col-1"></div>
                        <FormLabel className="col">
                            Proposed Remedial Action
                        </FormLabel>
                    </div>
                    <div className="row mb-2 mt-2">
                        <div className="col-2">
                            Are existing platforms suitable?
                        </div>
                        <div className="col-1">
                            <FormSelect
                                options={[
                                    { label: "Yes", value: true },
                                    { label: "No", value: false },
                                ]}
                                defaultValue={review.platformsSuitable}
                                isMulti={false}
                                onBlur={(selection) =>
                                    onFieldChanged(
                                        "platformsSuitable",
                                        selection.value
                                    )
                                }
                            />
                        </div>
                        <div className="col">
                            <FormTextArea
                                value={review.platformRemedialActionComment}
                                onBlur={(value) =>
                                    onFieldChanged(
                                        "platformRemedialActionComment",
                                        value
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className="row mb-2 mt-2">
                        <div className="col-2">
                            Are existing providers suitable?
                        </div>
                        <div className="col-1">
                            <FormSelect
                                options={[
                                    { label: "Yes", value: true },
                                    { label: "No", value: false },
                                ]}
                                defaultValue={review.providersSuitable}
                                isMulti={false}
                                onBlur={(selection) =>
                                    onFieldChanged(
                                        "providersSuitable",
                                        selection.value
                                    )
                                }
                            />
                        </div>
                        <div className="col">
                            <FormTextArea
                                value={review.providersRemedialActionComment}
                                onBlur={(value) =>
                                    onFieldChanged(
                                        "providersRemedialActionComment",
                                        value
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className="row mb-2 mt-2">
                        <div className="col-2">
                            Are existing products suitable?
                        </div>
                        <div className="col-1">
                            <FormSelect
                                options={[
                                    { label: "Yes", value: true },
                                    { label: "No", value: false },
                                ]}
                                defaultValue={review.productsSuitable}
                                isMulti={false}
                                onBlur={(selection) =>
                                    onFieldChanged(
                                        "productsSuitable",
                                        selection.value
                                    )
                                }
                            />
                        </div>
                        <div className="col">
                            <FormTextArea
                                value={review.productsRemedialActionComment}
                                onBlur={(value) =>
                                    onFieldChanged(
                                        "productsRemedialActionComment",
                                        value
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className="row mb-2 mt-2">
                        <div className="col-2">
                            Is existing drawdown suitable?
                        </div>
                        <div className="col-1">
                            <FormSelect
                                options={[
                                    { label: "Yes", value: 0 },
                                    { label: "No", value: 1 },
                                    { label: "N/A", value: 2 },
                                ]}
                                defaultValue={review.existingDrawdownSuitable}
                                isMulti={false}
                                onBlur={(selection) =>
                                    onFieldChanged(
                                        "existingDrawdownSuitable",
                                        selection.value
                                    )
                                }
                            />
                        </div>
                        <div className="col">
                            <FormTextArea
                                value={review.drawdownRemedialActionComment}
                                onBlur={(value) =>
                                    onFieldChanged(
                                        "drawdownRemedialActionComment",
                                        value
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className="row mb-2 mt-2">
                        <div className="col-2">
                            Is existing investment strategy suitable?
                        </div>
                        <div className="col-1">
                            <FormSelect
                                options={[
                                    { label: "Yes", value: true },
                                    { label: "No", value: false },
                                ]}
                                defaultValue={review.investmentStrategySuitable}
                                onBlur={(selection) =>
                                    onFieldChanged(
                                        "investmentStrategySuitable",
                                        selection.value
                                    )
                                }
                            />
                        </div>
                        <div className="col">
                            <FormTextArea
                                value={
                                    review.investmentStrategyRemedialActionComment
                                }
                                onBlur={(value) =>
                                    onFieldChanged(
                                        "investmentStrategyRemedialActionComment",
                                        value
                                    )
                                }
                            />
                        </div>
                    </div>
                </>
            )}
            <div className="row mb-2 mt-2">
                <div className="col-3">
                    <FormSelect
                        label={"Review Conclusion"}
                        options={[
                            { label: "Not Set", value: null, showConfirm: false },
                            { label: "No Changes Required", value: 1, showConfirm: true },
                            { label: "Changes Advised", value: 2, showConfirm: true },
                        ]}
                        defaultValue={review.reviewConclusion}
                        onChange={(selection) =>{
                            onFieldChanged("reviewConclusion", selection.value);
                            if(selection.showConfirm && review.meetingNoteStatus != 2){
                                setShowConfirmModal(true);
                            }}
                        }
                    />
                </div>
            </div>
            {review.reviewConclusion != 0 && 
            <div className="row mb-2 mt-2">
                <div className="col">
                    <RichTextEditor
                        label={
                            review.reviewConclusion == 1
                                ? "Text for No Change Letter"
                                : "Recommendations"
                        }
                        defaultValue={review.recommendations}
                        onBlur={(value) =>
                            onFieldChanged("recommendations", value)
                        }
                    />
                </div>
            </div>}
        </React.Fragment>
    );
};

export default ReviewConclusion;
