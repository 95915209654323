import { VirtualTable } from "../../../components/tables";
import { StandardTableSortableHeader, StickyThemedTableHeader } from "../../../components/tables/StandardTable";
import { TableRecordCountDisplayFooter } from "../../../components/tables/VirtualTable";
import usePortfolioTestResults from "../hooks/usePortfolioTestResults";
import PortfolioTestResultsTableRow from "./PortfolioTestResultsTableRow";

const PortfolioTestResultsGrid = () => {
    const {
        clear,
        isClearing,
        isRowLoaded,
        loadMore,
        currentSort,
        splitSort: [sortProperty, sortDirection],
        onClickSort,
        totalCount,
        results,
        columns,
        error
    } = usePortfolioTestResults();

    const footerSpan = Math.floor(columns.length / 2);
    return <div className="p-3">
        <VirtualTable
            enableScrollTracking
            row={(props) => <PortfolioTestResultsTableRow columns={columns} {...props} />}
            header={<thead>
                <tr>
                    {columns.map((col, index) =>
                        col.headerRenderer
                            ? col.headerRenderer({ index, ...col, currentSort, onClickSort })
                            : col.sort
                                ? <StandardTableSortableHeader
                                    key={index}
                                    active={sortProperty === col.sortProperty ?? col.property}
                                    direction={sortDirection}
                                    onClick={() => onClickSort(col.sortProperty ?? col.property)}
                                    isSticky
                                >
                                    {col.label}
                                </StandardTableSortableHeader>
                                : <StickyThemedTableHeader key={index} >
                                    {col.label}
                                </StickyThemedTableHeader>
                    )}
                </tr>
            </thead>}
            footer={<TableRecordCountDisplayFooter
                className="sticky-footer w-100"
                totalCount={totalCount}
                isNewRecordsDisplayed={false}
                newRecordsSinceCache={0}
                reload={clear}
                spanLeft={footerSpan}
                spanRight={columns.length - footerSpan}
            />}
            error={error}
            isLoading={isClearing}
            isItemLoaded={isRowLoaded}
            loadMoreItems={loadMore}
            itemSize={46}
            itemCount={totalCount === results.length ? totalCount : results.length + 3}
            itemData={results}
        />
    </div>
}

export default PortfolioTestResultsGrid;