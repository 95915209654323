import { Controller } from "react-hook-form";

import {
    GenericUserSelect,
    ReportAuthorSelect,
    ReportStatusSelect,
} from "../../../components/forms/selects";
import { DateInput, FormTextArea, FormCheck } from "../../../components/forms";
import PortfolioValuationStatusSelect from "../../../components/forms/selects/PortfolioValuationStatusSelect";
import DrawdownStatusSelect from "../../clientreviews/components/DrawdownStatusSelect";
import {CenterFormColumnComponent } from "../../../components/utilities";
import FFMeetingNotesStatusSelect from "../../clientreviews/components/FFMeetingNotesStatusSelect";
import ReviewAppointmentStatusSelect from "../../clientreviews/components/ReviewAppointmentStatusSelect";

const OverwriteReview = ({formDetails}) => {

    return (
    <div className="p-3">
        <div className="p-1 mb-2">
            <div className="card">
                <div className="card-body">
                    <div className="row mb-3">
                        <div className="col-4">
                            <Controller
                                name="verificationStatus"
                                control={formDetails.control}
                                render={({
                                    field: {ref, value, onChange, ...rest}}) => (
                                    <PortfolioValuationStatusSelect
                                        label="Portfolio Valuation"
                                        defaultValue={value}
                                        onBlur={(selection) =>
                                            formDetails.setValue(
                                                "verificationStatus",
                                                selection.value,
                                                { shouldDirty: true }
                                            )
                                        }
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller
                                name="verifiedById"
                                control={formDetails.control}
                                render={({
                                    field: {ref, value, onChange, ...rest}}) => (
                                    <GenericUserSelect
                                        label="Valuation Verified By"
                                        defaultValue={value}
                                        placeholder="No user selected"
                                        onChange={(values) =>
                                            formDetails.setValue(
                                                "verifiedById",
                                                values.value,
                                                { shouldDirty: true }
                                            )
                                        }
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <Controller
                                name="verifiedDate"
                                control={formDetails.control}
                                render={({ field: { ref, onBlur, onChange, ...rest } }) => (
                                    <DateInput
                                        label="Valuation Verified On"
                                        placeholder="Choose verified date."
                                        disableAnimations={true}
                                        isClearable
                                        onBlur={(value, e) => {
                                            if (value !== "Invalid date") {
                                                formDetails.setValue("verifiedDate", value, {shouldDirty: true });
                                            }
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <Controller
                                name="verificationStatusComment"
                                control={formDetails.control}
                                render={({ field: { ref, value, onBlur, onChange, ...rest } }) => (
                                    <FormTextArea
                                    label="Verification Comment"
                                    value={value}
                                    onBlur={(value) =>
                                        formDetails.setValue("verificationStatusComment", value,
                                            { shouldDirty: true })
                                    }
                                />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-2">
                            <CenterFormColumnComponent>
                                <Controller
                                    name="isDrawdownReview"
                                    control={formDetails.control}
                                    rules={{ required: false }}
                                    render={({ field: { ref, value, onChange, ...rest } }) => 
                                        <FormCheck
                                            label={"Is Drawdown"}
                                            isChecked={value}
                                            errorAllowRetry={false}
                                            disableAnimations
                                            onChange={(value) => formDetails.setValue("isDrawdownReview", value,
                                                { shouldDirty: true })}
                                            {...rest}
                                            />
                                        }
                                />
                                </CenterFormColumnComponent>
                        </div>
                        <div className="col">
                            <Controller
                                name="drawdownStatus"
                                control={formDetails.control}
                                render={({
                                    field: {ref, value, onChange, ...rest}}) => (
                                        <DrawdownStatusSelect  
                                            label="Drawdown Status"
                                            defaultValue={value}
                                            onBlur={(selection) =>
                                                formDetails.setValue(
                                                    "drawdownStatus",
                                                    selection.value,
                                                    { shouldDirty: true }
                                                )
                                            }
                                        />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="drawdownVerifiedById"
                                control={formDetails.control}
                                render={({
                                    field: {ref, value, onChange, ...rest}}) => (
                                    <GenericUserSelect
                                        label="Drawdown Verified By"
                                        defaultValue={value}
                                        placeholder="No user selected"
                                        onChange={(values) =>
                                            formDetails.setValue(
                                                "drawdownVerifiedById",
                                                values.value,
                                                { shouldDirty: true }
                                            )
                                        }
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="drawdownVerifiedDate"
                                control={formDetails.control}
                                render={({ field: { ref, onBlur, onChange, ...rest } }) => (
                                    <DateInput
                                        label="Drawdown Verified On"
                                        placeholder="Choose verified date."
                                        disableAnimations={true}
                                        isClearable
                                        onBlur={(value, e) => {
                                            if (value !== "Invalid date") {
                                                formDetails.setValue("drawdownVerifiedDate",value,{ shouldDirty: true });
                                            }
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <Controller
                                name="drawdownStatusComment"
                                control={formDetails.control}
                                render={({ field: { ref, value, onBlur, onChange, ...rest } }) => (
                                    <FormTextArea
                                    label="Drawdown Status Comment"
                                    value={value}
                                    onBlur={(value) =>
                                        formDetails.setValue("drawdownStatusComment", value,
                                            { shouldDirty: true })
                                    }
                                />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <Controller
                                name="preReviewDocumentStatusId"
                                control={formDetails.control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <ReportStatusSelect
                                        label="PRD Up Status"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        isClearable={true}
                                        placeholder="Choose PRD Status"
                                        onChange={(values) => formDetails.setValue('preReviewDocumentStatusId', values.value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="preReviewDocumentAuthorId"
                                control={formDetails.control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <ReportAuthorSelect
                                        label="PRD Author"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        isClearable={true}
                                        placeholder="Choose Follow Up Author"
                                        onChange={(values) => formDetails.setValue('preReviewDocumentAuthorId', values.value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <Controller
                                name="preReviewDocumentAuthorAssignedDate"
                                control={formDetails.control}
                                render={({ field: { ref, onBlur, onChange, ...rest } }) => (
                                    <DateInput
                                        label="PRD Author Assigned Date"
                                        placeholder="Choose assigned date."
                                        disableAnimations={true}
                                        isClearable
                                        onBlur={(value, e) => {
                                            if (value !== "Invalid date") {
                                                formDetails.setValue("preReviewDocumentAuthorAssignedDate", value,{ shouldDirty: true });
                                            }
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="preReviewDocumentCompliantDate"
                                control={formDetails.control}
                                render={({ field: { ref, onBlur, onChange, ...rest } }) => (
                                    <DateInput
                                        label="PRD Compliant Date"
                                        placeholder="Choose compliant date."
                                        disableAnimations={true}
                                        isClearable
                                        onBlur={(value, e) => {
                                            if (value !== "Invalid date") {
                                                formDetails.setValue("preReviewDocumentCompliantDate",value,{ shouldDirty: true });
                                            }
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="preReviewDocumentSentDate"
                                control={formDetails.control}
                                render={({ field: { ref, onBlur, onChange, ...rest } }) => (
                                    <DateInput
                                        label="PRD Sent Date"
                                        placeholder="Choose sent date."
                                        disableAnimations={true}
                                        isClearable
                                        onBlur={(value, e) => {
                                            if (value !== "Invalid date") {
                                                formDetails.setValue("preReviewDocumentSentDate",value,{ shouldDirty: true });
                                            }
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <Controller
                                name="preReviewDocumentStatusComment"
                                control={formDetails.control}
                                render={({ field: { ref, value, onBlur, onChange, ...rest } }) => (
                                    <FormTextArea
                                    label="PRD Status Comment"
                                    value={value}
                                    onBlur={(value) =>
                                        formDetails.setValue("preReviewDocumentStatusComment", value,
                                            { shouldDirty: true })
                                    }
                                />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <Controller
                                name="appointmentStatus"
                                control={formDetails.control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <ReviewAppointmentStatusSelect
                                        label="Appointment Status"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        isClearable={true}
                                        placeholder="Choose Appointment Status"
                                        onChange={(values) => formDetails.setValue('appointmentStatus', values.value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="appointmentScheduledById"
                                control={formDetails.control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <GenericUserSelect
                                        label="Scheduled By"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        isClearable={true}
                                        placeholder="Choose scheduled by."
                                        onChange={(values) => formDetails.setValue('appointmentScheduledById', values.value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="appointmentScheduledDate"
                                control={formDetails.control}
                                render={({ field: { ref, onBlur, onChange, ...rest } }) => (
                                    <DateInput
                                        label="Appointment Scheduled Date"
                                        placeholder="Choose scheduled date."
                                        disableAnimations={true}
                                        isClearable
                                        onBlur={(value, e) => {
                                            if (value !== "Invalid date") {
                                                formDetails.setValue("appointmentScheduledDate",value,{ shouldDirty: true });
                                            }
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <Controller
                                name="appointmentStatusComment"
                                control={formDetails.control}
                                render={({ field: { ref, value, onBlur, onChange, ...rest } }) => (
                                    <FormTextArea
                                    label="Appointment Status Comment"
                                    value={value}
                                    onBlur={(value) =>
                                        formDetails.setValue("appointmentStatusComment", value,
                                            { shouldDirty: true })
                                    }
                                />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <Controller
                                name="meetingNoteStatus"
                                control={formDetails.control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <FFMeetingNotesStatusSelect
                                        label="Meeting Note Status"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        isClearable={true}
                                        placeholder="Choose PRD Status"
                                        onChange={(values) => formDetails.setValue('meetingNoteStatus', values.value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>  
                        <div className="col">
                            <Controller
                                name="meetingNotesUpdatedById"
                                control={formDetails.control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <GenericUserSelect
                                        label="Meeting Note Updated By"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        isClearable={true}
                                        placeholder="Choose Meeting Note Author"
                                        onChange={(values) => formDetails.setValue('meetingNotesUpdatedById', values.value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="meetingNotesUpdatedDate"
                                control={formDetails.control}
                                render={({ field: { ref, onBlur, onChange, ...rest } }) => (
                                    <DateInput
                                        label="Meeting Note Updated Date"
                                        placeholder="Choose assigned date."
                                        disableAnimations={true}
                                        isClearable
                                        onBlur={(value, e) => {
                                            if (value !== "Invalid date") {
                                                formDetails.setValue("meetingNotesUpdatedDate",value,{ shouldDirty: true });
                                            }
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <Controller
                                name="followUpReportStatusId"
                                control={formDetails.control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <ReportStatusSelect
                                        label="Follow Up Status"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        isClearable={true}
                                        placeholder="Choose PRD Status"
                                        onChange={(values) => formDetails.setValue('followUpReportStatusId', values.value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="followUpAuthorId"
                                control={formDetails.control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <ReportAuthorSelect
                                        label="Follow Up Author"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        isClearable={true}
                                        placeholder="Choose Follow Up Author"
                                        onChange={(values) => formDetails.setValue('followUpAuthorId', values.value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        {/* Add compliant date and author assigned date */}
                        <div className="col">
                            <Controller
                                name="followUpAuthorAssignedDate"
                                control={formDetails.control}
                                render={({ field: { ref, onBlur, onChange, ...rest } }) => (
                                    <DateInput
                                        label="Follow Up Author Assigned Date"
                                        placeholder="Choose assigned date."
                                        disableAnimations={true}
                                        isClearable
                                        onBlur={(value, e) => {
                                            if (value !== "Invalid date") {
                                                formDetails.setValue("followUpAuthorAssignedDate", value,{ shouldDirty: true });
                                            }
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="followUpCompliantDate"
                                control={formDetails.control}
                                render={({ field: { ref, onBlur, onChange, ...rest } }) => (
                                    <DateInput
                                        label="Follow Up Compliant Date"
                                        placeholder="Choose compliant date."
                                        disableAnimations={true}
                                        isClearable
                                        onBlur={(value, e) => {
                                            if (value !== "Invalid date") {
                                                formDetails.setValue("followUpCompliantDate",value,{ shouldDirty: true });
                                            }
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="followUpSentDate"
                                control={formDetails.control}
                                render={({ field: { ref, onBlur, onChange, ...rest } }) => (
                                    <DateInput
                                        label="Follow Up Sent Date"
                                        placeholder="Choose sent date."
                                        disableAnimations={true}
                                        isClearable
                                        onBlur={(value, e) => {
                                            if (value !== "Invalid date") {
                                                formDetails.setValue("followUpSentDate",value,{ shouldDirty: true });
                                            }
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <Controller
                                name="followUpStatusComment"
                                control={formDetails.control}
                                render={({ field: { ref, value, onBlur, onChange, ...rest } }) => (
                                    <FormTextArea
                                        label="Follow Up Status Comment"
                                        value={value}
                                        onBlur={(value) =>
                                            formDetails.setValue("followUpStatusComment", value,
                                                { shouldDirty: true })
                                        }
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <Controller
                                name="reviewOverwriteComment"
                                control={formDetails.control}
                                rules={{ required: "Please provide a reason for the overwrite." }}
                                render={({ field: { ref, value, onBlur, onChange, ...rest } }) => (
                                    <FormTextArea
                                        label="Review Overwrite Comment"
                                        value={value}
                                        onBlur={(value) =>
                                            formDetails.setValue("reviewOverwriteComment", value)
                                        }
                                        errorMessage={formDetails.formState.errors?.reviewOverwriteComment?.message}  
                                        errorAllowRetry={false}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )

}

export default OverwriteReview;