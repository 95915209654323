import { FormSelect } from "../../../components/forms";

const PortfolioValuationViewSelector = ({className, ...rest}) => {

    const PortfolioViewOptions = [
        { value: 1, label: "Valuation" },
        { value: 2, label: "Portfolio Profile" },
        { value: 3, label: "Investments Summary" },
        { value: 4, label: "Investments Summary by Volatility" },
        { value: 5, label: "Historical Total Returns" },
        { value: 6, label: "Historical Total Returns by Volatility" },
        { value: 7, label: "Historical Annual Returns" },
    ];

return (
    <FormSelect
        className={className}
        options={PortfolioViewOptions}
        {...rest}
    />
);

};

export default PortfolioValuationViewSelector;
