import Skeleton from "react-loading-skeleton";
import PortfolioWrapper from "./PortfolioWrapper";

const PortfolioProfile = ({profile, isLoadingProfile}) => {

    if(isLoadingProfile){
        return(
            <PortfolioWrapper label="Loading Investments Summary...">
                <Skeleton />
            </PortfolioWrapper>
        );
    }

    // if(error){
    //     return (
    //         <PortfolioWrapper label="Investments Summary">
    //             <div className="flex flex-col w-full text-center">
    //                 <p>Could not load Investments Summary</p>
    //             </div>
    //         </PortfolioWrapper>
    //     );
    // }

    return( 
        <>
            <PortfolioWrapper label="Dashboard Comment">
                <span className="" dangerouslySetInnerHTML={{
                    __html: profile.dashboardAndPRDComment.notes
                }} ></span>
            </PortfolioWrapper>
        </>
    );
}

export default PortfolioProfile;