import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button, IconButton } from "../../../components";
import { CurrencyInput, FormInput, FormLabel, FormSelect, PercentageInput } from "../../../components/forms";
import GenericAdviserSelect from "../../../components/forms/selects/GenericAdviserSelect";
import { Spinner } from "../../../components/loaders";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import useNewMoney from "../hooks/useNewMoney";

const NewMoneyRow = ({ data, addRow, deleteRow, patchRow, className, index }) =>
    <div className={`row gx-2 ${className ?? ""}`} key={data?.newMoneyId ?? index}>
        <div className="col col-xl-2">
            {data == null
                ? <FormLabel className="ps-1">Flow</FormLabel>
                : <FormSelect
                    defaultValue={data.isOutflow ?? false}
                    onChange={(selection) => patchRow('isOutflow', selection.value)}
                    options={[
                        { label: "New Money", value: false },
                        { label: "Outflow", value: true }
                    ]}
                    disableAnimations
                />}
        </div>
        <div className="col col-sm-1 col-md-2 col-xl-3">
            {data == null
                ? <FormLabel className="ps-1">Adviser</FormLabel>
                : <GenericAdviserSelect
                    onChange={(e) => patchRow('adviserId', e.value)}
                    defaultValue={data.adviserId}
                    disableAnimations
                    errorMessage={data.errors?.includes('AdviserId') ? "Please select an adviser." : null}
                    errorAllowRetry={false}
                />}
        </div>
        <div className="col col-sm-1 col-lg-3">
            {data == null
                ? <FormLabel className="ps-1">Description</FormLabel>
                : <FormInput
                    onChange={(e) => patchRow('description', e.target.value)}
                    value={data.description}
                    disableAnimations
                />}
        </div>
        <div className="col">
            {data == null
                ? <FormLabel className="ps-1">Investment</FormLabel>
                : <CurrencyInput
                    onChange={(_, values) => patchRow('investmentAmount', Math.floor(values.floatValue))}
                    value={data.investmentAmount}
                    disableAnimations
                    decimalScale={0}
                    fixedDecimalScale
                    errorMessage={data.errors?.includes('InvestmentAmount') ? "Please enter a valid investment amount." : null}
                    errorAllowRetry={false}
                />}
        </div>
        <div className="col">
            {data == null
                ? <FormLabel className="ps-1">Rate (%)</FormLabel>
                : <PercentageInput
                    onChange={(_, values) => patchRow('serviceFeePercent', values.floatValue)}
                    value={data.serviceFeePercent}
                    disableAnimations
                    decimalScale={2}
                    fixedDecimalScale
                    errorMessage={data.errors?.includes('ServiceFeePercent') ? "Please enter a valid fee percentage." : null}
                    errorAllowRetry={false}
                />}
        </div>
        <div className="col">
            {data == null
                ? <FormLabel className="ps-1">Service Fee</FormLabel>
                : <CurrencyInput
                    value={data.serviceFeeAmount}
                    disabled
                    disableAnimations
                    decimalScale={0}
                    fixedDecimalScale
                />}
        </div>
        <div className="col-auto">
            <IconButton
                icon={data == null ? "circle-plus" : "fa-trash-can"}
                variant={data == null ? "success" : "danger"}
                onClick={data == null ? addRow : deleteRow}
            />
        </div>
    </div>;


const NewMoneyModal = ({ show, onHide }) => {
    const {
        newMoney,
        isFetching,
        isError,
        error,
        refetch,
        addRow,
        deleteRow,
        patchRow,
        cancelChanges,
        saveChanges,
        isSaving,
        // saveIsError,
        // saveError
    } = useNewMoney({ closeModal: onHide })

    return <Modal show={show} onHide={onHide} size="xl" backdrop="static" centered>
        <Modal.Header>
            <Modal.Title>Register Value of New Service Fee Paying Investment Flows</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {isFetching
                ? <Spinner />
                : isError
                    ? <ErrorMessageWrapper>
                        <ErrorMessage
                            error={error}
                            message={"Could not fetch/add new money to this business sheet."}
                            retryCallback={refetch}
                        />
                    </ErrorMessageWrapper>
                    : newMoney?.length > 0
                        ? <>
                            {/* Header Row */}
                            <NewMoneyRow addRow={addRow} index={-1} />

                            {/* Data Rows */}
                            {newMoney?.map((row, index) => <NewMoneyRow
                                key={row.newMoneyId ?? index}
                                className="mt-1"
                                deleteRow={() => deleteRow(index)}
                                patchRow={(prop, newValue) => patchRow(index, prop, newValue)}
                                data={row}
                                index={index}
                            />)}
                        </>
                        : <p className="my-2">No New Money is registered for this business sheet. Click <button className="anchor" onClick={addRow}>here</button> to add some.</p>}
        </Modal.Body>
        {!isError && <Modal.Footer>
            <Button variant="danger" onClick={cancelChanges} disabled={isSaving}>
                Cancel
            </Button>
            <Button variant="success" onClick={saveChanges} disabled={isSaving}>
                {isSaving ? 'Saving...' : 'Save Changes'}
            </Button>
        </Modal.Footer>}
    </Modal>;
}

const NewMoney = ({ newMoneySum, hasNewMoney, newMoneyHasErrors }) => {
    const [show, setShow] = useState(false);
    const onHide = () => setShow(false);

    return <>
        <Button variant={newMoneyHasErrors ? "danger" : "primary"} onClick={() => setShow(true)}>
            {hasNewMoney
                ? newMoneySum > 0
                    ? `New Money: ${newMoneySum?.toLocaleString("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 })}`
                    : `Outflow: ${Math.abs(newMoneySum)?.toLocaleString("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 })}`
                : "Register Inflow or Outflow"}
        </Button>
        <NewMoneyModal show={show} onHide={onHide} />
    </>;
}

export default NewMoney;