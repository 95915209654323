import React from "react";
import usePortfolioHistoricalAnnualReturns from "../hooks/usePortfolioHistoricalAnnualReturns";
import PortfolioWrapper from "./PortfolioWrapper";
import Skeleton from "react-loading-skeleton";
import { NumericFormat } from "react-number-format";
import { useClientContext } from "../../../hooks/ClientContext";
import { ThemedSpan } from "../../../components/utilities";
import { ViewPdfModal } from "../../../components/modals";
import useFundFactSheets from "../hooks/useFundFactSheets";

const PortfolioHistoricalAnnualReturnsView = () => {

    const { id } = useClientContext();

    const [{
        showPreview,
        hidePreview
    }, {
        pdfPreview,
        isGettingPdfPreview,
        shouldShowPdfPreview,
        getPdfPreviewError
    }] = useFundFactSheets();

    const { data = null, error = false, isFetching = false, isLoading = false } = usePortfolioHistoricalAnnualReturns(id);

    if(isLoading || isFetching){
        return(
            <PortfolioWrapper label={"Loading Historical Annual Total Returns..."}>
                <Skeleton count={4}/>
            </PortfolioWrapper>
        );
    }

    if(error){
        return (
            <PortfolioWrapper label={"Historical Annual Total Returns"}>
                <div className="flex flex-col w-full text-center text-zinc-600">
                    <p>Some error here</p>
                </div>
            </PortfolioWrapper>
        );
    }

    return (  <>
        <ViewPdfModal
            title="Fact Sheet Preview"
            data={pdfPreview}
            show={shouldShowPdfPreview}
            handleClose={hidePreview}
            isLoading={isGettingPdfPreview}
            error={getPdfPreviewError}
            backdrop
        />
        <PortfolioWrapper label={"Historical Annual Total Returns"}>
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th>Investment</th>
                        <th className="text-end">Value</th>
                        <th className="text-end">{data.firstYearHeading}</th>
                        <th className="text-end"></th>
                        <th className="text-end">{data.secondYearHeading}</th>
                        <th className="text-end"></th>
                        <th className="text-end">{data.thirdYearHeading}</th>
                        <th className="text-end"></th>
                        <th className="text-end">{data.fourthYearHeading}</th>
                        <th className="text-end"></th>
                        <th className="text-end">{data.fifthYearHeading}</th>
                        <th className="text-end"></th>
                    </tr>
                </thead>
                <tbody className="overflow-x-auto border-top-0">
                    {data.investments.length > 0 && data.investments.map((inv, index) => (
                        <tr key={index}>
                            {inv.assetGroupId != null &&
                                <td>
                                    <button className="btn btn-link p-0"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            showPreview(inv.assetGroupId);}
                                            }>
                                        {inv.investmentName}
                                    </button>
                                </td>
                            }
                            {inv.assetGroupId == null && <td>{inv.investmentName}</td>}
                            <td className="text-end"> 
                                    <NumericFormat
                                        value={inv.value}
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        displayType="text"
                                        prefix={"£"}
                                        />
                            </td>
                            
                           
                            <td className="text-end">
                                <ThemedSpan variant={inv.discreteTotalReturn0To12Months < 0 ? 'danger' : 'success'}>
                                    <NumericFormat
                                        value={inv.discreteTotalReturn0To12Months}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        displayType="text"
                                        suffix={"%"}
                                    />
                                </ThemedSpan>
                            </td>
                            <td>
                                {inv.quartileTotalReturn0To12Months != null && <span>  Q{inv.quartileTotalReturn0To12Months}  </span>}
                            </td>
                             <td className="text-end">
                                <ThemedSpan variant={inv.discreteTotalReturn12To24Months < 0 ? 'danger' : 'success'}>
                                    <NumericFormat
                                        value={inv.discreteTotalReturn12To24Months}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        displayType="text"
                                        suffix={"%"}
                                    />
                                </ThemedSpan>
                            </td>
                            <td>
                                {inv.quartileTotalReturn12To24Months != null && <span>  Q{inv.quartileTotalReturn12To24Months}  </span>}
                            </td>
                            <td className="text-end">
                               <ThemedSpan variant={inv.discreteTotalReturn24To36Months < 0 ? 'danger' : 'success'}>
                                    <NumericFormat
                                        value={inv.discreteTotalReturn24To36Months}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        displayType="text"
                                        suffix={"%"}
                                    />
                                </ThemedSpan>
                            </td>
                             <td>
                                {inv.quartileTotalReturn24To36Months != null && <span>  Q{inv.quartileTotalReturn24To36Months}  </span>}
                            </td>
                            <td className="text-end">
                               <ThemedSpan variant={inv.discreteTotalReturn36To48Months < 0 ? 'danger' : 'success'}>
                                    <NumericFormat
                                        value={inv.discreteTotalReturn36To48Months}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        displayType="text"
                                        suffix={"%"}
                                    />
                                </ThemedSpan>
                            </td>
                             <td>
                                {inv.quartileTotalReturn36To48Months != null && <span>  Q{inv.quartileTotalReturn36To48Months}  </span>}
                            </td>
                            <td className="text-end">
                                <ThemedSpan variant={inv.discreteTotalReturn48To60Months < 0 ? 'danger' : 'success'}>
                                    <NumericFormat
                                        value={inv.discreteTotalReturn48To60Months}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        displayType="text"
                                        suffix={"%"}
                                    />
                                </ThemedSpan>
                            </td>
                            <td>
                                {inv.quartileTotalReturn48To60Months != null && <span>  Q{inv.quartileTotalReturn48To60Months}  </span>}
                            </td>
                        </tr>
                    ))}
                    {data == null || data.investments.length <= 0 && 
                        <tr>
                            <td>There are no investments to show.</td>
                        </tr>
                    }     
                </tbody>
            </table>
        </div>
</PortfolioWrapper>
</>
);

}

export default PortfolioHistoricalAnnualReturnsView;