import { useCallback, useEffect, useReducer } from "react";
import { useFetchNewMoneyQuery, useSaveNewMoneyMutation } from "../../../services/recommendations";
import { useBizSheet } from "../contexts/BizSheetContext";

const newMoneyReducer = (state, action) => {
    switch (action.type) {
        case 'set':
            return action.value;
        case 'map':
            return state.map(action.map);
        case 'add':
            return [
                ...state,
                action.value
            ];
        case 'remove':
            return state.filter((_, index) => index !== action.index);
        case 'update':
            return state.map((item, index) => {
                if (index !== action.index)
                    return item;

                return {
                    ...item,
                    ...action.value,
                    modifiedById: null
                };
            });
        default:
            return state;
    }
}

const useNewMoney = ({ closeModal }) => {
    // Reducer/fetch logic for updating displayed list
    const [, { selectedSheetId, sheet }] = useBizSheet();
    const { data: serviceNewMoney, isFetching, isError, error, refetch } = useFetchNewMoneyQuery({ bizSheetId: selectedSheetId }, { refetchOnReconnect: true, refetchOnFocus: true, refetchOnMountOrArgChange: true })
    const [newMoney, dispatch] = useReducer(newMoneyReducer, []);

    const setList = (newList) => dispatch({ type: 'set', value: newList });
    const updateServiceFees = () => dispatch({
        type: 'map',
        map: row => ({
            ...row,
            serviceFeeAmount: Math.floor((row.investmentAmount ?? 0) * (row.serviceFeePercent ?? 0) / 100)
        })
    })
    const addRow = useCallback(() => dispatch({
        type: 'add',
        value: {
            businessSheetId: selectedSheetId,
            masterAccountId: sheet?.masterAccountId,
            investmentAmount: 0,
            serviceFeePercent: 0
        }
    }), [selectedSheetId, sheet?.masterAccountId]);
    const deleteRow = (index) => dispatch({ type: 'remove', index });
    const patchRow = (index, property, newValue) => dispatch({
        type: 'update',
        index,
        value: { [property]: newValue }
    });
    const cancelChanges = () => {
        closeModal();
        if (serviceNewMoney.length > 0)
            setList(serviceNewMoney);
    }

    // Endpoint logic for saving changes
    const [saveChangesTrigger, { isLoading: isSaving, isError: saveIsError, error: saveError }] = useSaveNewMoneyMutation();
    const saveChanges = () => saveChangesTrigger({ bizSheetId: selectedSheetId, updatedList: newMoney }).unwrap()
        .then(closeModal)
        .then(refetch);

    useEffect(() => {
        // Clear the reducer when the business sheet changes
        setList([])
    }, [selectedSheetId]);

    useEffect(() => {
        if (isFetching) return;

        setList(serviceNewMoney);
    }, [addRow, isFetching, serviceNewMoney]);

    // Maintain service fee amount at service fee % of total investment for each row
    useEffect(() => {
        if (isFetching || newMoney.length === 0) return;

        updateServiceFees();

        // EsLint disabled because we only want to update when the relevant values change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, newMoney?.reduce((acc, curr) => acc + curr.investmentAmount, 0), newMoney?.reduce((acc, curr) => acc + curr.serviceFeePercent, 0)]);

    return {
        newMoney,
        isFetching,
        isError,
        error,
        refetch,
        addRow,
        deleteRow,
        patchRow,
        cancelChanges,
        saveChanges,
        isSaving,
        saveIsError,
        saveError
    };
}

export default useNewMoney;