import React from "react";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEditReview } from "../../reviewmanagementeditmodal/hooks/EditReviewContext";
import { useOverwriteReview } from "../../revieweditmodal/hooks/OverwriteReviewContext";


const ReviewManagementDetailTableRowActionColumnParent = styled.td``;

const ReviewManagementDetailTableRowActionColumnToggle = React.forwardRef(({ ...rest }, ref) => {
    return (
        <div ref={ref} {...rest}>
            <FontAwesomeIcon icon="fa-ellipsis" className="has-pointer" />
        </div>
    );
});

const ReviewManagementDetailTableRowActionColumn = ({ review }) => {

    const menuId = React.useId();
    const { setReview } = useEditReview();
    const { setReview : setOverwriteReview } = useOverwriteReview();

    const onHandleClickShowEditModal = () => {
        setReview(review);
    };

    const onHandleClickShowOverwriteModal = () => {
        setOverwriteReview(review.reviewId);
    };

    return (
        <ReviewManagementDetailTableRowActionColumnParent>
            <Dropdown className="caret-off">
                <Dropdown.Toggle id={menuId} as={ReviewManagementDetailTableRowActionColumnToggle} />
                <Dropdown.Menu>
                    <Dropdown.Item onClick={onHandleClickShowEditModal}>
                        <FontAwesomeIcon icon="fa fa-pencil" className="has-pointer" size="sm" /> <span className="ms-1">Assign Details</span>
                    </Dropdown.Item><Dropdown.Item onClick={onHandleClickShowOverwriteModal}>
                        <FontAwesomeIcon icon="fa fa-pencil" className="has-pointer" size="sm" /> <span className="ms-1">Overwrite Review Details</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </ReviewManagementDetailTableRowActionColumnParent>
    );
};

export default ReviewManagementDetailTableRowActionColumn;