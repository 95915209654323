import React, { useCallback, useEffect, useId, useMemo, useRef, useState } from "react";
import Select from "react-select";
import { useFetchClientProfilesQuery } from "../../../services/assets";
import FormGroup from "../FormGroup";
import FormInputGroup from "../FormInputGroup";
import FormLabel from "../FormLabel";
import { CalculateClassName, CalculateSelectStyles } from "../FormSelect";
import InputErrorMessage from "../InputErrorMessage";

const ClientProfileSelect = React.forwardRef(({
    className = "",
    components = {},
    errorMessage = null,
    errorAllowRetry = false,
    horizontal = false,
    id,
    isMulti = false,
    label,
    setValue = () => console.warn('set value method has not been assigned.'),
    value,
    ...rest
}, ref) => {
    const defaultId = useId();
    const componentId = id || defaultId;
    const defaultRef = useRef();
    const componentRef = ref || defaultRef;
    const [error, setError] = useState(null);
    const { data, isFetching } = useFetchClientProfilesQuery();

    const currentClassName = useMemo(() => CalculateClassName(className, false, error, 'flex-fill'), [className, error]);
    const currentStyles = useMemo(() => CalculateSelectStyles(error), [error]);

    const getDisplayValue = useCallback((selectedValue, data, isMulti) => {
        if (!value || !data || !Array.isArray(data))
            return null;

        if (isMulti === true) {
            let selectedValues = Array.isArray(selectedValue) ? selectedValue : [selectedValue];
            return data.filter(({ value }) => selectedValues.includes(value));
        }

        return data.find(({ value }) => value === selectedValue) ?? null;
    }, [value]);

    useEffect(() => {
        if (errorMessage)
            setError(_ => errorMessage);
        else
            setError(_ => null);
    }, [errorMessage]);

    const displayValue = useMemo(() => getDisplayValue(value, data, isMulti), [getDisplayValue, value, data, isMulti]);
    return <FormGroup className={className} horizontal={horizontal}>
        {label && <FormLabel htmlFor={componentId} horizontal={horizontal}>{label}</FormLabel>}
        <FormInputGroup className={"has-error"} horizontal={horizontal} hasLabel={label ? true : false}>
            <Select
                id={componentId}
                ref={componentRef}
                isMulti={isMulti}
                components={components}
                styles={currentStyles}
                className={currentClassName}
                value={displayValue}
                options={data}
                isLoading={isFetching}
                onChange={setValue}
                {...rest}
            />
            <InputErrorMessage errorMessage={error} allowRetry={errorAllowRetry} />
        </FormInputGroup>
    </FormGroup>
});

export default ClientProfileSelect;