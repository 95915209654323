import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import MomentComponent from "react-moment";
import styled from "styled-components";
import { Button, IconButton } from "../../../components";
import {
    FormLabel,
    FormTextArea
} from "../../../components/forms";
import { CenterFormColumnComponent } from "../../../components/utilities";
import useOpenDocument from "../../../hooks/useOpenDocument";
import CreateReportModal from "../../createreportmodal";
import SelectReportModal from "./SelectReportModal";
import TargetDate from "./TargetDate";
import { useAuth } from "react-oidc-context";
import _ from "lodash";
import { ConfirmModal } from "../../../components/modals";
import DocumentSelectModal from "./DocumentSelectModal";

const StatusDisplay = styled.span`
    font-size: 16px;
    font-weight: bold;
`;

const FollowUpReport = ({ 
    review, 
    onFieldChanged, 
    select, 
    followUpType,
    allowReassignReportRoles = ["sys_admin_access","report_admin","correct_review_details"]
 }) => {


    const { user: { profile }} = useAuth();
    const isAllowedToReassignReport = _.some(profile.roles, item => allowReassignReportRoles.includes(item));
    
    const { openDocument, isFetching: isOpening } = useOpenDocument();


    const followUpReportType = [{
        value: followUpType?.id,
        label: followUpType?.description
    }];

    //Create new report
    const [showCreateReport, setShowCreateReport] = useState(false);
    const handleCreateReportClose = () => setShowCreateReport(false);
    const handleOnReportCreated = (r) => {
        handleOnReportSelected(r);
    };


    //Select existing report
    const [showSelectReport, setShowSelectReport] = useState(false);
    const handleSelectReportClose = () => setShowSelectReport(false);
    const handleOnReportSelected = (report) => {
        return select(report);
    }

    //Select existing document
    const [showSelectDocument, setShowSelectDocument] = useState(false);
    const handleSelectDocumentClose = () => setShowSelectDocument(false);
    const handleOnDocumentSelected = (report) => {
        return select(report);
    }

    //Confirm Modal
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmModalTitle, setConfirmModalTitle] = useState("Are you sure?");
    const [confirmModalMessage, setConfirmModalMessage] = useState("Are you sure you want to do this?");
    const [confirmModalConfirmFn, setConfirmModalConfirmFn] = useState(null);
    const handleOpenConfirmModal = (title, message, confirmFn) => {

        setConfirmModalTitle(title);
        setConfirmModalMessage(message);
        setConfirmModalConfirmFn(() => confirmFn);

        setShowConfirmModal(true);
    }

    return (
        <React.Fragment>
            <CreateReportModal
                show={showCreateReport}
                defaultProjectId={review?.projectId}
                handleClose={handleCreateReportClose}
                onReportCreated={handleOnReportCreated}
                reportTypeOptionsOverride={followUpReportType}
                defaultReportTypeId={followUpType?.id}
            />
            <SelectReportModal
                show={showSelectReport}
                handleClose={handleSelectReportClose}
                onReportSelected={handleOnReportSelected}
                reportType="Follow Up Report"
            />
             <DocumentSelectModal
                show={showSelectDocument}
                handleClose={handleSelectDocumentClose}
                onDocumentSelected={handleOnDocumentSelected}
                reportType="Follow Up Report"
                defaultValues={{
                    statusId: review?.followUpReportStatusId,
                    authorId: review?.followUpAuthorId,
                    authorAssignedDate: review?.followUpAuthorAssignedDate,
                    compliantDate: review?.followUpCompliantDate,
                    sentDate: review?.followUpSentDate,
                    documentId: review?.followUpGraphId,
                    id: review?.followUpReportId,
                }}
            />
            <ConfirmModal
                title={confirmModalTitle}
                message={confirmModalMessage}
                handleClose={() => setShowConfirmModal(false)}
                handleConfirm={() => {
                    if(typeof(confirmModalConfirmFn) === "function"){
                        confirmModalConfirmFn(true);
                    }

                    setShowConfirmModal(false);
                }}
                confirmButtonVariant="danger"
                show={showConfirmModal}
            />
            <div className="row mt-4">
                <div className="col-2">
                    <div className="row">
                        <FormLabel className="col">Follow Up Status</FormLabel>
                    </div>
                    <div className="row">
                        <StatusDisplay className="col">
                            {review.followUpReportStatusText}
                        </StatusDisplay>
                    </div>
                </div>

                <div className="col">
                    <FormTextArea
                        label="Comment"
                        value={review.followUpStatusComment}
                        onBlur={(value) =>
                            onFieldChanged("followUpStatusComment", value)
                        }
                        disabled={!review.followUpRequired}
                    />
                </div>

                <div className="col-2 align-items-end">
                    <FormLabel className="col">Follow Up Report</FormLabel>

                    {!isAllowedToReassignReport && review.followUpReportId != null && review.followUpGraphId != null &&
                        <Button
                            variant="primary"
                            className="w-100"
                            onClick={() => openDocument(review.followUpGraphId)}
                            disabled={!review.followUpRequired || isOpening}
                        >
                            Open
                        </Button>
                    }
                    
                    {!isAllowedToReassignReport
                        && review.followUpReportId == null
                        && review.followUpGraphId == null &&
                        <Button
                            variant="primary"
                            className="w-100"
                            onClick={() => setShowCreateReport(true)}
                            disabled={!review.followUpRequired || isOpening || review?.reviewStatus === 1 || review?.reviewStatus === 2}
                        >
                            Create
                        </Button>
                    }

                    {isAllowedToReassignReport && 
                        <Dropdown>
                        <Dropdown.Toggle
                        variant="success"
                        className="w-100 flex-fill"
                        disabled={!review.followUpRequired}
                        >
                                Create/Select
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {review.followUpReportId != null && review.followUpGraphId != null &&
                                    <>
                                        <Dropdown.Item onClick={() => openDocument(review.followUpGraphId)} disabled={!review.followUpRequired || isOpening}>Open Report</Dropdown.Item>
                                        <Dropdown.Divider />
                                    </>
                                }
                                <Dropdown.Item onClick={() => handleOpenConfirmModal("Create new report", "Are you sure you want to create a new report? This will replace the follow up currently associated with this review.", setShowCreateReport)}>Create Report</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleOpenConfirmModal("Select existing document", "Are you sure you want to select an existing document? This will replace the follow up currently associated with this review.", setShowSelectDocument)}>Select PDF</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleOpenConfirmModal("Select existing report", "Are you sure you want to select an existing report? This will replace the follow up currently associated with this review.", setShowSelectReport)}>Select Word Report</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </div>

                <div className="col-md-auto d-flex align-items-end mb-1">
                    <CenterFormColumnComponent>

                        <IconButton
                            className="col-auto"
                            icon={review.followUpRequired ? "fa-x" : "fa-check"}
                            variant={review.followUpRequired ? "danger" : "success"}
                            onClick={(_) =>
                                onFieldChanged(
                                    "followUpRequired",
                                    !review.followUpRequired
                                )
                            }
                        />
                    </CenterFormColumnComponent>
                </div>
            </div>

            <div className="row">
                <div className="col-3">
                    {review.followUpAuthor &&
                        review.followUpAuthorAssignedDate && (
                            <FormLabel>
                                Assigned to {review.followUpAuthor} on
                                <MomentComponent
                                    className="ps-1"
                                    format="DD/MM/YYYY HH:mm"
                                >
                                    {review.followUpAuthorAssignedDate}
                                </MomentComponent>
                            </FormLabel>
                        )}
                </div>

                <div className="col-6">
                    <div className="row">
                        <div className="col d-flex justify-content-start">
                            {review.followUpCompliantDate && (
                                <FormLabel>
                                    Compliant:
                                    <MomentComponent
                                        className="ps-1"
                                        format="DD/MM/YYYY HH:mm"
                                    >
                                        {review.followUpCompliantDate}
                                    </MomentComponent>
                                </FormLabel>
                            )}
                        </div>
                        <div className="col d-flex justify-content-start">
                            {review.followUpSentDate && (
                                <FormLabel>
                                    Sent:
                                    <MomentComponent
                                        className="ps-1"
                                        format="DD/MM/YYYY HH:mm"
                                    >
                                        {review.followUpSentDate}
                                    </MomentComponent>
                                </FormLabel>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-3 d-flex justify-content-end">
                    {review.followUpSentTargetDate && (
                        <TargetDate
                            date={review.followUpSentDate}
                            targetDate={review.followUpSentTargetDate}
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default FollowUpReport;
