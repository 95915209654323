import React from "react";
import { patchReplace } from "../../../helpers/patchDoc";
import { useLazySearchInvestmentTransactionsQuery, useDuplicateTransactionMutation, usePatchInvestmentTransactionMutation, useCreateTransactionMutation } from "../../../services/valuations";
import { useFilterContext } from "../../../hooks/FilterContext";

const useTransactions = (masterAccountId, initSort="investmentDate+desc", initLimit=50) => {

    const { filter, isReady } = useFilterContext();

    const [duplicate, {}] = useDuplicateTransactionMutation();
    const [patch, {}] = usePatchInvestmentTransactionMutation();
    const [create, {isLoading : isCreating}] = useCreateTransactionMutation();

    const [cacheKey, setCacheKey] = React.useState(new Date().valueOf());

    const [sort, setSort] = React.useState(initSort);
    const [limit, setLimit] = React.useState(initLimit);

    const [searchTrigger, { data, isLoading, isFetching, isError, error, isUninitialized }] = useLazySearchInvestmentTransactionsQuery();

    const { pagination, results } = data || { pagination: { page: 1, pageSize: limit, totalCount: 0, totalPages: 1 }, results: [] };
    const { page, totalCount, totalPages } = pagination;
    const hasMore = totalCount > 0 && page < totalPages;
    const isNextPageLoading = isFetching === true || isLoading === true;

    const [isClearing, setIsClearing] = React.useState(false);
    
    const clear = (newSort) => {
        // safety check to make sure we don't reload when already loading!
        if (isNextPageLoading || !isReady) {
            return;
        }

        const newCacheKey = new Date().valueOf();

        setIsClearing(true);
        searchTrigger({
            cacheKey: newCacheKey,
            sort,
            limit,
            page: 1,
            ...filter, 
            masterAccountId: masterAccountId
        }).unwrap().then(_ => {
            setCacheKey(_ => newCacheKey)
            if (newSort) {
                setSort(_ => newSort);
            }
        }).finally(_ => setIsClearing(false));
    };

    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        clear();
    };

    const isRowLoaded = (index) => !hasMore || index < results.length;

    const loadMore = () => {

        // safety check to make sure we don't reload when already loading!
        if (isNextPageLoading) {
            return;
        }

        searchTrigger({
            cacheKey,
            sort,
            limit,
            page: page + 1,
            ...filter, 
            masterAccountId: masterAccountId
        });
    }

    const sortTable = (property) => {
        // we want the sort to initially be descending ??
        let newDirection = "";
        let [sortProperty, direction] = sort.split("+");

        // if the current property is already selected, reverse the sort direction
        if (property === sortProperty) {
            newDirection = direction === "DESC" ? null : "+DESC";
        }

        setSort(property + (newDirection ?? ""));
    };

    React.useEffect(clear, [filter, sort, isReady]);

    const createTransaction = (e) => {
        if (e && typeof e.preventDefault === "function") {
            e.preventDefault();
        }

        return new Promise((resolve, reject) => {
            return create({masterAccountId })
                .unwrap()
                .then(
                    (r) => resolve(r),
                    (e) => reject(e)
                );
        });
    };

    const updateField = (investmentTransactionId, property, value) => {
        return new Promise((resolve, reject) => {
            let operations = [patchReplace(property, value)];

            return patch({ investmentTransactionId, masterAccountId, operations })
                .unwrap()
                .then(
                    (r) => resolve(r),
                    (e) => reject(e)
                );
        });
    };

    const duplicateTransaction = (investmentTransactionId) => {
        return new Promise((resolve, reject) => {
            return duplicate({
                investmentTransactionId: investmentTransactionId,
                masterAccountId: masterAccountId,
            })
                .unwrap()
                .then(
                    (r) => resolve(r),
                    (e) => reject(e)
                );
        });
    };

    return { 
        results: isClearing ? [] : results,
        sort,
        totalCount,
        isError,
        error,
        isNextPageLoading,
        clear,
        isRowLoaded,
        loadMore,
        sortTable,
        reload,
        createTransaction,
        isCreating,
        updateField,
        duplicateTransaction,
    };
}

export default useTransactions;