import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { Alert, Button, ButtonGroup, Col, Container, Nav, Navbar, Offcanvas, Row } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import { IconButton } from "../../../components";
import { FormCheck } from "../../../components/forms";
import { LoadingToggler, Spinner } from "../../../components/loaders";
import ViewPdfModal from "../../../components/modals/ViewPdfModal";
import { useSendToAdminMutation } from "../../../services/recommendations";
import BusinessSheetSearch from "../../bizsheetsearch";
import CreateBusinessSheetModal from "../../createbizsheetmodal";
import { useBizSheet } from "../contexts/BizSheetContext";
import useTestCertificate from "../hooks/useTestCertificate";
import BizSheetPdfPreviewModal from "./BizSheetPdfPreviewModal";
import NewMoney from "./NewMoney";

// Allowed statuses to edit the read-only status of the sheet are:
// Author Assigned, Await Author, Await Compliance, Await Feedback, Draft Report, Rejected, Await Further Compliance, Compliance Assigned, Compliance Locked, and Drafting
const editableStatuses = [0, 2, 3, 4, 6, 8, 9, 11, 18, 19]

const BizSheetTopBar = ({ instructionTypes }) => {
    const [{
        setSelectedSheetId,
        applyCosts,
        createInstruction,
        showPdf,
        hidePdf,
        makeReadonly
    }, {
        selectedSheetId,
        fetchedSheetId,
        sheet,
        sheetIsLoading,
        isApplyingCosts,
        pdfData,
        isGettingPdf,
        shouldShowPdf,
        getPdfError
    }, {
        sheetHasErrors,
        setSheetHasErrors
    }] = useBizSheet();

    const [showInstructions, setShowInstructions] = useState(false);
    const [showCreateSheet, setShowCreateSheet] = useState(false);
    const show = () => setShowInstructions(true);
    const hide = () => setShowInstructions(false);

    useEffect(() => {
        // Show the instruction add menu (once the selected sheet has been loaded) if there are no instructions on the sheet
        if (selectedSheetId && sheet?.instructions?.length === 0 && (selectedSheetId === fetchedSheetId) && !sheetIsLoading) {
            setShowInstructions(true);
        }
    }, [fetchedSheetId, selectedSheetId, sheet?.instructions?.length, sheetIsLoading]);

    // User needs compliance role to make/unmake read-only or send to admin
    const { user } = useAuth();
    const isCompliance = user.profile.roles.includes("report_compliance") || user.profile.roles.includes("sys_admin_access");

    const [validateTrigger, { isLoading: isValidating }] = useSendToAdminMutation();

    const validateAndSendToAdmin = useCallback(() => {
        setSheetHasErrors(null);

        validateTrigger({ bizSheetId: selectedSheetId, instructionIds: sheet?.instructions?.map(i => i.id) })
            .unwrap()
            .then(res => {
                setSheetHasErrors(!res.isValid);
            });
    }, [selectedSheetId, setSheetHasErrors, sheet?.instructions, validateTrigger]);

    const {
        isLoadingProfiles,
        testPortfolio,
        showTestCertificate,
        testCertificatePdfData,
        closeTestCertificate,
        isLoading: isTesting,
        error: portfolioTestError
    } = useTestCertificate(selectedSheetId);

    return <>
        <CreateBusinessSheetModal
            show={showCreateSheet}
            handleClose={() => setShowCreateSheet(false)}
            setSelectedSheetId={setSelectedSheetId}
        />
        <BizSheetPdfPreviewModal
            show={shouldShowPdf}
            data={pdfData}
            handleClose={() => {
                hidePdf();
                // Display warning if no new money has been registered, and the sheet is still editable
                if (sheet?.newMoney?.length === 0 && !sheet?.isReadOnly)
                    alert("Please ensure that there are no New Money/Outflows to be registered before sending to compliance.");
            }}
            isLoading={isGettingPdf}
            error={getPdfError}
            refetch={showPdf}
        />
        <ViewPdfModal
            show={showTestCertificate}
            data={testCertificatePdfData}
            handleClose={closeTestCertificate}
            isLoading={isTesting}
            error={portfolioTestError}
            refetch={testPortfolio}
        />
        <Container fluid>
            <Row className="d-flex justify-content-between align-items-center">
                <Col xs="auto">
                    {sheet != null && sheet?.instructions?.length > 0
                        ? <Button
                            className="me-2"
                            variant="info"
                            onClick={showPdf}>
                            Show PDF
                        </Button>
                        : <></>}
                    {sheet && `Status: ${sheet.statusDescription}`}
                </Col>
                <Col xs="auto">
                    {sheet
                        ? <ButtonGroup>
                            <Button
                                variant="warning"
                                onClick={testPortfolio}
                                disabled={isTesting || isLoadingProfiles}
                            >
                                Portfolio Test Recommendations
                            </Button>
                            <Button
                                variant={isApplyingCosts ? "secondary" : "primary"}
                                disabled={isApplyingCosts || sheet.productCostsApplied}
                                onClick={applyCosts}>
                                {isApplyingCosts ? <Spinner /> : "Apply Costs"}
                            </Button>
                            {isCompliance && <Button
                                variant="secondary"
                                onClick={validateAndSendToAdmin}
                                disabled={isValidating}
                            >
                                {isValidating
                                    ? <FontAwesomeIcon icon="spinner" spinPulse />
                                    : 'Send to Admin'}
                            </Button>}
                        </ButtonGroup>
                        : <></>}
                </Col>
            </Row>
        </Container>
        <Navbar className="d-flex flex-column align-items-start w-100 border-bottom py-2" variant="light" expand={false}>
            <Container fluid>
                <Navbar.Text className="d-flex flex-row align-items-center">
                    <Col xs="auto">
                        Select Advice Sheet:
                    </Col>
                    <Col xs className="ms-2">
                        <BusinessSheetSearch
                            value={sheet?.reportName}
                            onSelect={(selectedSheet) => {
                                setSelectedSheetId(selectedSheet.id);
                            }}
                        />
                    </Col>
                    <Col xs="auto">
                        <IconButton
                            icon={"circle-plus"}
                            variant={"success"}
                            className={"mx-2"}
                            onClick={() => setShowCreateSheet(true)}
                        />
                    </Col>
                    <Col xs="auto">
                        {sheet && <NewMoney
                            newMoneySum={sheet?.newMoneySum ?? 0}
                            hasNewMoney={sheet?.newMoney?.length > 0}
                            newMoneyHasErrors={sheet?.newMoney?.some(i => i.errors?.length > 0)}
                        />}
                    </Col>
                </Navbar.Text>

                {sheet != null && (sheet?.instructions?.length === 0
                    ? <Navbar.Text className="ms-auto me-2 my-auto">Click here to add your first instruction:</Navbar.Text>
                    : <>
                        <FormCheck
                            groupClassName="my-auto ms-auto me-3"
                            // labelClassName="my-auto me-4"
                            label="Read-only:"
                            labelFirst
                            horizontal
                            disabled={!(isCompliance && editableStatuses.includes(sheet?.status))} // Only compliance can make/unmake read-only, and only on pre-compliant sheets
                            checked={sheet?.isReadOnly}
                            onChange={makeReadonly}
                        />
                    </>)}

                <LoadingToggler
                    disabled={!selectedSheetId || sheet?.isReadOnly}
                    disabledText={sheet?.isReadOnly
                        ? "Cannot add an instruction to a read-only business sheet"
                        : "Select an Advice Sheet to edit"}
                    enabledText="Add an Instruction"
                    onClick={show}
                    className="me-1"
                />

                <Navbar.Offcanvas
                    id="offcanvasNavbar-expand-false"
                    placement="end"
                    show={showInstructions}
                    onHide={hide}
                >
                    <Offcanvas.Header>
                        <Offcanvas.Title
                            id="offcanvasNavbar-expand-false"
                        >
                            Add Instruction
                        </Offcanvas.Title>

                        <Button variant="secondary" className="btn-close text-reset" onClick={hide} />
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {instructionTypes?.map((type, index) => (
                            <Nav.Link
                                key={type?.id ?? index}
                                className="align-text-left"
                                onClick={() => {
                                    createInstruction(type?.id);
                                    hide();
                                }}
                            >
                                {type.description}
                            </Nav.Link>
                        ))}
                    </Offcanvas.Body>
                </Navbar.Offcanvas>

            </Container>
            <div className="d-flex">
                {sheetHasErrors && <Alert variant="danger">
                    <Alert.Heading>Errors in sheet</Alert.Heading>
                    There are errors in this sheet preventing it from being sent to admin.
                    <br />
                    Please see the marked instructions for how to fix these issues.
                </Alert>}

            </div>
        </Navbar>
    </>;
}

export default BizSheetTopBar;