import React from "react";
import { useLazyFetchProjectRemindersQuery, usePatchReminderTaskMutation } from "../../../services/reminders";
import { patchReplace } from "../../../helpers/patchDoc";

const useProjectReminders = (masterAccountId, projectId) => {
    const [loadReminders, loadResults] = useLazyFetchProjectRemindersQuery();
    const [patchReminder, patchResults] = usePatchReminderTaskMutation();

    const [showCancelled, setShowCancelled] = React.useState(true);

    React.useEffect(() => {
        loadReminders({ masterAccountId, projectId, showCancelled })
    }, [loadReminders, masterAccountId, projectId, showCancelled]);

    const handlePatchReminder = (reminderId, property, value) => {
        return new Promise((resolve, reject) => {
            patchReminder({ reminderId, operations: [patchReplace(property, value)] })
                .unwrap()
                .then(resolve, reject);
        });
    }

    return {
        showCancelled,
        setShowCancelled,
        handlePatchReminder,
        patchResults,
        ...loadResults
    };
};

export default useProjectReminders;