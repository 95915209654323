import React from "react";
import FormSelect from "../FormSelect";

export const Statuses = [
    { label: 'Awaiting Verification', value: 0 },
    { label: 'Verified', value: 1 },
    { label: 'Cancelled', value: 2 },
    { label: 'Verification in Progress', value: 3 },
];

const PortfolioValuationStatusSelect = ({ options, ...rest }) => {
    return <FormSelect
        // options={Statuses.sort((a, b) => a.label > b.label ? 1 : -1)}
        options={Statuses}
        {...rest}
    />
};

export default PortfolioValuationStatusSelect;