import React from "react";
import PortfolioWrapper from "./PortfolioWrapper";
import Skeleton from "react-loading-skeleton";
import { NumericFormat } from "react-number-format";
import { useClientContext } from "../../../hooks/ClientContext";
import useValuationDetails from ".././hooks/useValuationDetails";
import PortfolioDesignationSummaryHeaders from "./PortfolioDesignationSummaryHeaders";
import PortfolioDesignationSummary from "./PortfolioDesignationSummary";

const ValuationView = ({data}) => {

    // const { id } = useClientContext();
    // const { data, error, isError, isLoading, isFetching, reload } = useValuationDetails(id);

    // if(isLoading || isFetching){
    //     return(
    //         <PortfolioWrapper>
    //             <Skeleton />
    //         </PortfolioWrapper>
    //     );
    // }

    // if(error){
    //     return (
    //         <PortfolioWrapper>
    //             <div className="flex flex-col w-full text-center text-zinc-600">
    //                 <p>Broke</p>
    //             </div>
    //         </PortfolioWrapper>
    //     );
    // }

    return <>
        {data != null && data.designations != null && data.designations.map((el, i) => (
            <PortfolioWrapper 
            key={`designation-${i}`}
            label={`${el.designation.designation} - 
            £${new Intl.NumberFormat('en-GB').format(el.designationValue)
            }`}>


                <PortfolioDesignationSummaryHeaders  key={`header-${i}`} />
                {el.wrappers != null && el.wrappers.map((wrapper, j) =>
                        wrapper.products != null &&
                        wrapper.products.map((product) => (
                            <PortfolioDesignationSummary  key={`item-${j}`} designation={el} wrapper={wrapper} product={product}/>
                        ))
                    )}
            </PortfolioWrapper>
            ))}
        </>

}

export default ValuationView;