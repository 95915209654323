import React, { useMemo } from "react";
import FormSelect from "../FormSelect";
import { useFetchServiceAgreementsQuery } from "../../../services/clientserviceterms";

const ServiceAgreementsSelect = ({ options, divisionId = null, includeNoneOption = false, ...rest }) => {
    const { data, error, isError, isLoading, isFetching } = useFetchServiceAgreementsQuery({ });

    const displayOptions = useMemo(() => {
        if (isError || isFetching) return [];
        return includeNoneOption
            ? [{ label: "None", value: null }, ...data]
            : data;
    }, [data, includeNoneOption, isError, isFetching]);

    return <FormSelect
        options={displayOptions}
        isLoadingOptions={isLoading || isFetching}
        {...rest}
    />
};

export default ServiceAgreementsSelect;