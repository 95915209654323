import _ from "lodash";
import React from "react";
import { useLazyFetchClientReviewQuery, usePatchOverwriteClientReviewMutation, useLazyFetchReviewOverwriteLogsQuery } from "../../../services/clientreviews";
import toast from "react-hot-toast";
import EditReviewModal from "../components/EditReviewModal";

const OverwriteReviewContext = React.createContext();

export const useOverwriteReview = () => React.useContext(OverwriteReviewContext);

export const OverwriteReviewProvider = ({ children, showModalOnReviewSet = true}) => {

    const [review, _setReview] = React.useState(null);
    const [overwriteLogs, _setOverwriteLogs] = React.useState(null);

    const [showEditReviewModal, _setShowEditReviewModal] = React.useState(false);
    const [showOverwriteLogsModal, _setShowOverwriteLogsModal] = React.useState(false);

    const [fetchReviewTrigger, { data, isLoading, isFetching, isError, error, isUninitialized }] = useLazyFetchClientReviewQuery();
    const [fetchOverwriteLogs, { data : overwriteData, isLoading : overwriteLogsLoading, isFetching : overwriteLogsFetching, isError : overwriteLogsIsError, error : overwriteLogsEror }] = useLazyFetchReviewOverwriteLogsQuery();

    const [patch, { isLoading : isPatching, ...patchResults }] = usePatchOverwriteClientReviewMutation();

    const clear = () => {
        _setReview(_ => null);
        _setShowEditReviewModal(_ => false);
        _setShowOverwriteLogsModal(_ => false);
    };

    const saveChanges = (operations, overwriteComment) => {
        const { reviewId } = review;
        return new Promise((resolve, reject) => {
            return patch({ reviewId: reviewId, operations, overwriteComment }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const setReview = (reviewId) => {
        //fetch the review
        fetchReviewTrigger({reviewId}).unwrap().then(result => {
            _setReview(result);
            _setShowEditReviewModal(true);
        });

        fetchOverwriteLogs({reviewId}).unwrap().then(result => {
            _setOverwriteLogs(result);
        });
    };

    return (
        <OverwriteReviewContext.Provider value={{ 
            clear,
            isLoading,
            isPatching,
            isEditReviewModalActive: showEditReviewModal,
            patch: { isLoading, ...patchResults },
            overwriteReview: review || {},
            overwriteLogs: overwriteLogs || {},
            saveChanges,
            setReview
        }}>
                <EditReviewModal />
                {children}
        </OverwriteReviewContext.Provider>
    );
};