import React, { useState, useEffect, useMemo } from "react";
import { useClientContext } from "../../hooks/ClientContext";
import { Main } from "../../components/utilities";
import usePortfolio from "./hooks/usePortfolio";
import PortfolioBreakdown from "./components/PortfolioBreakdown";
import PortfolioWrapper from "./components/PortfolioWrapper";
import Skeleton from "react-loading-skeleton";
import { ErrorMessage, ErrorMessageWrapper } from "../../components/messages";
import { useNavigate } from 'react-router-dom';
import { ThemedParagraph } from "../../components/utilities";
import PortfolioInvestmentsView from "./components/PortfolioInvestmentsView";
import ValuationView from "./components/ValuationView";
import PortfolioInvestmentsByVolatilityView from "./components/PortfolioInvestmentsByVolatilityView";
import PortfolioHistoricTotalReturnsView from "./components/PortfolioHistoricTotalReturnsView";
import PortfolioHistoricTotalReturnsByVolatility from "./components/PortfolioHistoricTotalReturnsByVolatility";
import PortfolioHistoricalAnnualReturnsView from "./components/PortfolioHistoricalAnnualReturnsView";
import PortfolioProfile from "./components/PortfolioProfile";
import useAgreedVolatilityProfile from "./hooks/useAgreedVolatilityProfile";
import usePortfolioChanges from "./hooks/usePortfolioChanges";


const ClientPortfolio = ({ transactionsUrl = "transactions" }) => {
    const { id } = useClientContext();
    const { data, error, isError, isLoading, isFetching, reload } = usePortfolio(id);
    const { isLoadingProfiles, profileSection } = useAgreedVolatilityProfile();
    const { isLoadingPortfolioChanges, portfolioChanges } = usePortfolioChanges();

    console.log(portfolioChanges);

    const navigate = useNavigate();

    const navigateToClientTransactions = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        navigate(`../${transactionsUrl}`, { relative: 'path' });
    };

    const [currentView, setCurrentView] = useState(1);

    const handleRetry = (e) => {
        e.preventDefault();
        reload(e);
    };

    const featureDisplay = useMemo(() => {

        switch(currentView) {
            case 1:
                return <ValuationView data={data} />
            case 2:
                return <PortfolioProfile profile={profileSection} isLoadingProfile={isLoadingProfiles} />;
            case 3:
                return <PortfolioInvestmentsView />;
            case 4:
                return <PortfolioInvestmentsByVolatilityView />;
            case 5:
                return <PortfolioHistoricTotalReturnsView />;
            case 6:
                return <PortfolioHistoricTotalReturnsByVolatility />;
             case 7:
                 return <PortfolioHistoricalAnnualReturnsView />;
            default:
                return null;
        }
    }, [currentView, data, profileSection]);

    
    if (error || isError === true) {
        return (
            <Main className="p-3">
                <PortfolioWrapper>
                    <ErrorMessageWrapper margin="0px auto">
                        <ErrorMessage
                            error={error}
                            retryCallback={handleRetry}
                        />
                    </ErrorMessageWrapper>
                </PortfolioWrapper>
            </Main>
        );
    }
    
    if(data == null && !isLoading && !isFetching){
        return (
            <Main className="p-3">

                <ThemedParagraph variant="muted">
                    Sorry! But, there are no investment transactions available for this account. Please create some  <a href={`../${transactionsUrl}`} onClick={navigateToClientTransactions}>investment transactions</a> to build the client portfolio.
                </ThemedParagraph>
            </Main>
        );
    }
    
    if (isLoading || isFetching) {
        return (
            <Main className="p-3">
                <PortfolioWrapper label="Breakdown">
                    <div className="row my-2">
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                    </div>
                    <div className="row my-2">
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                    </div>
                    <div className="row my-2">
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                    </div>
                </PortfolioWrapper>
                <PortfolioWrapper label="Designation">
                    <div className="row my-2">
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                    </div>
                    <div className="row my-2">
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col-3">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                    </div>
                    <div className="row my-2">
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col-3">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                    </div>
                    <div className="row my-2">
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col-3">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                        <div className="col">
                            <Skeleton height={20} />
                        </div>
                    </div>
                </PortfolioWrapper>
            </Main>
        );
    }

    return (
        <Main className="p-3">
            <PortfolioWrapper 
                label={(
                    <div className="container flex m-0 p-0">
                        <div className="row">
                            <span className="col">Breakdown</span>
                            <span className="col">{profileSection?.profiles[0]?.targetProfile}</span>
                            <span className="col">{(!profileSection?.profiles[0]?.isBespokePortfolio) ? profileSection?.profiles[0]?.standardPortfolio : "Bespoke"}</span>
                        </div>
                    </div>
                )}
                collapsedByDefault={true}>
                <PortfolioBreakdown data={data} portfolioChanges={portfolioChanges} currentView={currentView} setCurrentView={setCurrentView}/>
            </PortfolioWrapper>

            <div className="my-3">
                {featureDisplay}
            </div>
            
        </Main>
    );
};

export default ClientPortfolio;
