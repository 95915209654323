import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import MomentComponent from "react-moment";
import styled from "styled-components";
import { Button, IconButton } from "../../../components";
import { FormLabel, FormTextArea } from "../../../components/forms";
import { CenterFormColumnComponent } from "../../../components/utilities";
import useOpenDocument from "../../../hooks/useOpenDocument";
import CreateReportModal from "../../createreportmodal";
import SelectReportModal from "./SelectReportModal";
import TargetDate from "./TargetDate";
import { useAuth } from "react-oidc-context";
import _ from "lodash";
import { ConfirmModal } from "../../../components/modals";
import DocumentSelectModal from "./DocumentSelectModal";

const StatusDisplay = styled.span`
        font-size: 16px;
        font-weight:bold;
    `;

const PreReviewDocument = ({ 
    review, 
    onFieldChanged, 
    select, 
    amberWarning = 5, 
    prdType,
    allowReassignReportRoles = ["sys_admin_access","report_admin","correct_review_details" ] }) => {


    const { user: { profile }} = useAuth();
    const isAllowedToReassignReport = _.some(profile.roles, item => allowReassignReportRoles.includes(item));

    const { openDocument, isFetching: isOpening } = useOpenDocument();

    const [showCreateReport, setShowCreateReport] = useState(false);
    const handleCreateReportClose = () => setShowCreateReport(false);
    const handleOnReportCreated = (r) => {
        handleOnReportSelected(r);
    };

    const prdReportType = [{
        value: prdType?.id,
        label: prdType?.description
    }];

    const [showSelectReport, setShowSelectReport] = useState(false);
    const handleSelectReportClose = () => setShowSelectReport(false);
    const handleOnReportSelected = (report) => {
        return select(report);
    }

    const [showSelectDocument, setShowSelectDocument] = useState(false);
    const handleSelectDocumentClose = () => setShowSelectDocument(false);
    const handleOnDocumentSelected = (report) => {
        return select(report);
    }

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmModalTitle, setConfirmModalTitle] = useState("Are you sure?");
    const [confirmModalMessage, setConfirmModalMessage] = useState("Are you sure you want to do this?");
    const [confirmModalConfirmFn, setConfirmModalConfirmFn] = useState(null);

    const handleOpenConfirmModal = (title, message, confirmFn) => {

        setConfirmModalTitle(title);
        setConfirmModalMessage(message);
        setConfirmModalConfirmFn(() => confirmFn);

        setShowConfirmModal(true);
    }


    return (
        <React.Fragment>

            <CreateReportModal
                show={showCreateReport}
                defaultProjectId={review?.projectId}
                handleClose={handleCreateReportClose}
                onReportCreated={handleOnReportCreated}
                reportTypeOptionsOverride={prdReportType}
                defaultReportTypeId={prdType?.id}
            />
            <SelectReportModal
                show={showSelectReport}
                handleClose={handleSelectReportClose}
                onReportSelected={handleOnReportSelected}
                reportType="Pre-Review Document"
            />
            <DocumentSelectModal
                show={showSelectDocument}
                handleClose={handleSelectDocumentClose}
                onDocumentSelected={handleOnDocumentSelected}
                reportType="Pre-Review Document"
                defaultValues={{
                    statusId: review?.preReviewDocumentStatusId,
                    authorId: review?.preReviewDocumentAuthorId,
                    authorAssignedDate: review?.preReviewDocumentAuthorAssignedDate,
                    compliantDate: review?.preReviewDocumentCompliantDate,
                    sentDate: review?.preReviewDocumentSentDate,
                    documentId: review?.preReviewDocumentGraphId,
                    id: review?.preReviewDocumentReportId,
                }}
            />
            <ConfirmModal
                title={confirmModalTitle}
                message={confirmModalMessage}
                handleClose={() => setShowConfirmModal(false)}
                handleConfirm={() => {
                    if(typeof(confirmModalConfirmFn) === "function"){
                        confirmModalConfirmFn(true);
                    }

                    setShowConfirmModal(false);
                }}
                confirmButtonVariant="danger"
                show={showConfirmModal}
            />
            <div className="row mt-4">
                <div className="col-2">
                    <div className="row">
                        <FormLabel className="col">
                            PRD Status
                        </FormLabel>
                    </div>
                    <div className="row">
                        <StatusDisplay className="col">
                            {review.preReviewDocumentStatusText}
                        </StatusDisplay>
                    </div>
                </div>

                <div className="col">
                    <FormTextArea
                        label="Comment"
                        value={review.preReviewDocumentStatusComment}
                        onBlur={(value) =>
                            onFieldChanged(
                                "preReviewDocumentStatusComment",
                                value
                            )
                        }
                        disabled={!review.preReviewDocumentRequired}
                    />
                </div>

                <div className="col-2 align-items-end">
                    <FormLabel className="col">Pre Review Document</FormLabel>

                    {!isAllowedToReassignReport && review.preReviewDocumentReportId != null && review.preReviewDocumentGraphId != null &&
                        <Button
                            variant="primary"
                            className="w-100"
                            onClick={() => openDocument(review.preReviewDocumentGraphId)}
                            disabled={!review.preReviewDocumentRequired || isOpening}
                        >
                            Open
                        </Button>
                    }
                    
                    {!isAllowedToReassignReport
                        && review.preReviewDocumentReportId == null
                        && review.preReviewDocumentGraphId == null &&
                        <Button
                            variant="primary"
                            className="w-100"
                            onClick={() => setShowCreateReport(true)}
                            disabled={!review.preReviewDocumentRequired || isOpening || review?.reviewStatus === 1 || review?.reviewStatus === 2}
                        >
                            Create
                        </Button>
                    }

                    {isAllowedToReassignReport && 
                        <Dropdown>
                        <Dropdown.Toggle
                        variant="success"
                        className="w-100 flex-fill"
                        disabled={!review.preReviewDocumentRequired}
                        >
                                Create/Select
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {review.preReviewDocumentReportId != null && review.preReviewDocumentGraphId != null &&
                                <>
                                    <Dropdown.Item onClick={() => openDocument(review.preReviewDocumentGraphId)} disabled={!review.preReviewDocumentRequired || isOpening}>Open Report</Dropdown.Item>
                                    <Dropdown.Divider />
                                </>
                                }
                                <Dropdown.Item onClick={() => handleOpenConfirmModal("Create new report", "Are you sure you want to create a new report? This will replace the PRD currently associated with this review.", setShowCreateReport)}>Create Report</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleOpenConfirmModal("Select existing document", "Are you sure you want to select an existing document? This will replace the PRD currently associated with this review.", setShowSelectDocument)}>Select PDF</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleOpenConfirmModal("Select existing report", "Are you sure you want to select an existing report? This will replace the PRD currently associated with this review.", setShowSelectReport)}>Select Word Report</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </div>
                <div className="col-md-auto d-flex align-items-end">
                    <CenterFormColumnComponent>
                        <IconButton
                            className="col-auto"
                            icon={review.preReviewDocumentRequired ? "fa-x" : "fa-check"}
                            variant={review.preReviewDocumentRequired ? "danger" : "success"}
                            onClick={(_) =>
                                onFieldChanged(
                                    "preReviewDocumentRequired",
                                    !review.preReviewDocumentRequired
                                )
                            }
                        />
                    </CenterFormColumnComponent>
                </div>
            </div>

            <div className="row">
                <div className="col-3">
                    {review.preReviewDocumentAuthor && review.preReviewDocumentAuthorAssignedDate && (
                        <FormLabel>
                            Assigned to {review.preReviewDocumentAuthor}
                            <MomentComponent
                                className="ps-1"
                                format="DD/MM/YYYY HH:mm">
                                {review.preReviewDocumentAuthorAssignedDate}
                            </MomentComponent>
                        </FormLabel>
                    )}
                </div>

                <div className="col-6">
                    <div className="row">
                        <div className="col d-flex justify-content-start">
                            {review.preReviewDocumentCompliantDate && (
                                <FormLabel>
                                    Compliant:
                                    <MomentComponent
                                        className="ps-1"
                                        format="DD/MM/YYYY HH:mm"
                                    >
                                        {
                                            review.preReviewDocumentCompliantDate
                                        }
                                    </MomentComponent>
                                </FormLabel>
                            )}
                        </div>
                        <div className="col d-flex justify-content-start">
                            {review.preReviewDocumentSentDate && (
                                <FormLabel>
                                    Sent:
                                    <MomentComponent
                                        className="ps-1"
                                        format="DD/MM/YYYY HH:mm"
                                    >
                                        {review.preReviewDocumentSentDate}
                                    </MomentComponent>
                                </FormLabel>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-3 d-flex justify-content-end">
                    {review.preReviewDocumentSentTarget && (
                        <TargetDate date={review.preReviewDocumentSentDate} targetDate={review.preReviewDocumentSentTarget} />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default PreReviewDocument;
