import React, { useState, useEffect } from "react";
import withProviders from "../../hooks/withProviders";
import { useClientContext } from "../../hooks/ClientContext";
import ClientTransactionsGrid from "./components/ClientTransactionsGrid";
import { AgGridMain } from "../../components/utilities";
import { ClientTransactionsPageSubHeader } from "../../components/utilities/PageSubHeader";
import styled from "styled-components";
import { Main, ThemedIcon, ThemedParagraph } from "../../components/utilities";
import {
    ClientTransactionsFiltersModal,
    ClientTransactionsFiltersModalButton,
} from "../clienttransactionsfilters";
import { FilterProvider, InvestmentTransactionDefaultFilters, useFilterContext } from "../../hooks/FilterContext";
import { EditTransactionProvider } from "../clienttransactionsedit/hooks/EditTransactionContext";

const ClientTransactionsFiltersAppliedWarningMessage = styled(ThemedParagraph)`
    font-size: 12px !important;
`;

const ClientTransactions = () => {
    const { id } = useClientContext();
    const {
        clearFilter,
        filter: {},
        isFiltersApplied,
        setFilter,
    } = useFilterContext();
    
    const [isFiltersShown, setIsFiltersShown] = useState(false);

    return (
        <React.Fragment>
            <ClientTransactionsFiltersModal
                handleClose={() => setIsFiltersShown(false)}
                show={isFiltersShown}
                masterAccountId={id}
            />
            <Main className="d-flex flex-column">
                <ClientTransactionsPageSubHeader>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            {isFiltersApplied && (
                                <React.Fragment>
                                    <ThemedIcon
                                        icon="fa-triangle-exclamation"
                                        size="2x"
                                        variant="warning"
                                    />
                                    <ClientTransactionsFiltersAppliedWarningMessage className="ms-2 text-muted">
                                        Warning - Filters are currently being
                                        applied to your results.
                                        <br />
                                        <a href="#" onClick={clearFilter}>
                                            Click here
                                        </a>{" "}
                                        to clear your filters.
                                    </ClientTransactionsFiltersAppliedWarningMessage>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="d-flex flex-fill justify-content-end align-items-center">
                            <ClientTransactionsFiltersModalButton
                                variant="success"
                                className="ms-2"
                                onClick={() => setIsFiltersShown(true)}
                            />
                        </div>
                    </div>
                </ClientTransactionsPageSubHeader>
                    <section className="px-4 my-3 h-100">
                        <EditTransactionProvider>
                            <ClientTransactionsGrid masterAccountId={id} />
                        </EditTransactionProvider>
                    </section>
               </Main>
        </React.Fragment>
    );
};

export default ClientTransactions;
