import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { NumericFormat } from "react-number-format";

const PriceSummary = ({ asset }) => {

    const currencyCodes = [
        { code: "EUR", symbol: "€" },
        { code: "USD", symbol: "$" },
        { code: "AUD", symbol: "$" },
        { code: "YEN", symbol: "¥" },
        { code: "GBP", symbol: "£" },
        { code: "GBX", symbol: "GBX" },
    ];

    const getCurrencySymbol = (currencyCode) => {

        var symbol = currencyCodes.find(x =>  x.code === currencyCode);
        
        if(symbol != null){
            return ("(" + symbol.symbol + ")");
        } 

        return "";
    };

    const showPriceDate = (asset) => {

        if(asset?.assetPrice?.priceDate != null){
            return new Date(
                asset?.assetPrice?.priceDate
            ).toLocaleDateString("en-GB");
        }

        return "";

    }

    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip>
                    {asset.assetPrice != null && (
                        <div style={{ textAlign: "left" }}>
                            <b>Price Date:</b>{" "}
                                {showPriceDate(asset)}
                            <br />
                            <b>Mid:</b> {asset?.assetPrice?.midPrice?.toFixed(2)}
                            <br />
                            <b>Bid:</b> {asset?.assetPrice?.bidPrice?.toFixed(2)}
                            <br />
                            <b>Offer:</b> {asset?.assetPrice?.offerPrice?.toFixed(2)}
                            <br />
                        </div>
                    )}
                </Tooltip>
            }
        >
            <span>
                <NumericFormat
                        value={asset.priceUsed.toFixed(4)}
                        thousandSeparator={true}
                        displayType="text"
                        decimalScale={4}
                        prefix={""}
                        suffix={asset?.asset?.currency ?? ""}
                    />
            </span>
        </OverlayTrigger>
    );
};

export default PriceSummary;
