import { useCallback } from "react";
import useClientConnection from "../hooks/useClientConnection";
import EditClientModal from "./EditClientModal";
import EditConnectionModal from "./EditConnectionModal";
import FormSelectOpenModal from "./FormSelectOpenModal";

// Opens EditConnectionModal when the icon is clicked, and either EditClient or EditConnectionModal when a selected value is clicked, 
const SelectOpenConnectionModal = ({ options, defaultValue, onChange, onBlur, onHide }) => {

    const { createClientConnection } = useClientConnection();

    const createAndSelectConnection = useCallback(() => {
        return new Promise((resolve, reject) => {
            return createClientConnection()
                .then((result) => {
                    try {
                        onBlur([
                            ...options.filter(({ value }) => defaultValue.includes(value)),
                            {
                                value: result?.connectionId,
                                isConnection: true
                            }
                        ]);

                        return resolve(result);
                    } catch {
                        console.error("Error");
                        reject()
                    }
                }, (error) => {
                    console.error(error);
                    reject(error);
                })
        });
    }, [createClientConnection, defaultValue, onBlur, options]);

    const selectConnectionOrClient = (data) => data.isConnection
        ? { connectionId: data.value, connectionName: data.label, isConnection: true }
        : { clientId: data.value, clientName: data.label, isConnection: false }

    return <FormSelectOpenModal
        // Icon props
        icon={"plus"}
        buttonVariant={"success"}
        onIconClickFunction={createAndSelectConnection}
        IconModalComponent={EditConnectionModal}
        onOptionClickFunction={selectConnectionOrClient}
        getOptionModalComponent={({ isConnection }) => isConnection ? EditConnectionModal : EditClientModal}
        modalProps={{ size: "xl", onHide }}
        // Select props
        options={options}
        defaultValue={defaultValue}
        isMulti
        tabSelectsValue={false}
        onChange={onChange}
        onBlur={onBlur}
    />
};

export default SelectOpenConnectionModal;