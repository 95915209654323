import { useCallback, useState } from "react";
import { useLazyGetBizSheetTestCertificateQuery } from "../../../services/recommendations";
import toast from "react-hot-toast";
import { useFetchAgreedVolatilityProfileSectionQuery } from "../../../services/clientfactfind";
import { useClientContext } from "../../../hooks/ClientContext";

const useTestCertificate = (bizSheetId) => {
    const { id: masterAccountId } = useClientContext();
    const { data: profileSection, isLoading: isLoadingProfiles } = useFetchAgreedVolatilityProfileSectionQuery({ masterAccountId })
    const { profiles } = profileSection || {};
    
    const [showTestCertificate, setShowTestCertificate] = useState(false);
    const [testCertificatePdfData, setTestCertificatePdfData] = useState(null);
    const [portfolioTestTrigger, { isLoading, error }] = useLazyGetBizSheetTestCertificateQuery();

    const testPortfolio = useCallback(() => {
        if (profiles == null || profiles.length === 0)
            return toast.error(`Please add a target profile for this client before generating the test certificate`);

        toast.promise(portfolioTestTrigger({ bizSheetId, targetProfiles: profiles }).unwrap(), {
            loading: `Building the business sheet test certificate`,
            success: (res) => {
                if (res?.data == null)
                    return `Failed to build Test Certificate`;

                setTestCertificatePdfData(res?.data);
                setShowTestCertificate(true);

                return `Test Certificate is open`;
            },
            error: (err) => <div>Failed to build Test Certificate - {err?.data?.message}</div>
        });
    }, [bizSheetId, portfolioTestTrigger, profiles]);

    return {
        isLoadingProfiles,
        testPortfolio,
        showTestCertificate,
        testCertificatePdfData,
        closeTestCertificate: () => setShowTestCertificate(false),
        isLoading,
        error
    };
}

export default useTestCertificate;