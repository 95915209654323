import { useGetPortfolioHistoricalReturnsQuery } from "../../../services/valuations";

const usePortfolioHistoricalReturns = (masterAccountId) => {
    const { data, error, isError, isLoading, isFetching, isUninitialized, refetch } = useGetPortfolioHistoricalReturnsQuery({ masterAccountId }/*, { refetchOnMountOrArgChange: true }*/);

    return {
        data,
        isLoading,
        isFetching,
        isError,
        error,
        reload : refetch
    };

};

export default usePortfolioHistoricalReturns;