import { wealthProApi } from "../../redux/api";
import { useCacheKeyMergeHandler, useCacheKeySerializeQueryArgsHandler, useCacheKeyTransformResponseHandler } from "../../redux/helpers";

const portfolioTestingApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        exportTestCertificatePdf: builder.query({
            query: (request) => ({
                url: `api/valuations/PortfolioTesting/pdf`,
                method: 'POST',
                body: request
            }),
        }),
        searchPortfolioTestRuns: builder.query({
            query: ({ page, limit, filters, sort }) => ({
                url: 'api/valuations/PortfolioTesting/search',
                method: 'POST',
                body: {
                    page,
                    limit,
                    filters,
                    sort
                }
            }),
            serializeQueryArgs: useCacheKeySerializeQueryArgsHandler,
            transformResponse: useCacheKeyTransformResponseHandler,
            merge: useCacheKeyMergeHandler
        }),
        updateMasterAccountFlaggedStatus: builder.mutation({
            query: ({ masterAccountId, flaggedStatus }) => ({
                url: `api/valuations/PortfolioTesting/${masterAccountId}/flag`,
                method: 'PATCH',
                body: { flaggedStatus }
            }),
            async onQueryStarted({ masterAccountId, flaggedStatus }, { dispatch, queryFulfilled }) {
                try {
                    const updateGridResult = dispatch(wealthProApi.util.updateQueryData('searchPortfolioTestRuns', {}, (draft) => {
                        const { results } = draft;
                        const row = results.find((r) => r.masterAccountId === masterAccountId);

                        if (row != null)
                            row.masterAccountFlagStatus = flaggedStatus;
                    }));

                    try {
                        await queryFulfilled;
                    } catch (error) {
                        console.error(error, "Failed to update master account flagged status at service");
                        updateGridResult.undo();
                    }
                } catch (error) {
                    console.error(error, "Failed to update master account flagged status locally");
                }
            }
        }),
        updateMasterAccountFlaggedNotes: builder.mutation({
            query: ({ masterAccountId, notes }) => ({
                url: `api/valuations/PortfolioTesting/${masterAccountId}/notes`,
                method: 'PATCH',
                body: { notes }
            }),
            async onQueryStarted({ masterAccountId, notes }, { dispatch, queryFulfilled }) {
                try {
                    const updateGridResult = dispatch(wealthProApi.util.updateQueryData('searchPortfolioTestRuns', {}, (draft) => {
                        const { results } = draft;
                        const row = results.find((r) => r.masterAccountId === masterAccountId);

                        if (row != null)
                            row.masterAccountFlagNotes = notes;
                    }));

                    try {
                        await queryFulfilled;
                    } catch (error) {
                        console.error(error, "Failed to update master account flagged notes at service");
                        updateGridResult.undo();
                    }
                } catch (error) {
                    console.error(error, "Failed to update master account flagged notes locally");
                }
            }
        })
    })
});

export const {
    useLazyExportTestCertificatePdfQuery,
    useLazySearchPortfolioTestRunsQuery,
    useUpdateMasterAccountFlaggedStatusMutation,
    useUpdateMasterAccountFlaggedNotesMutation
} = portfolioTestingApiEndpoints;