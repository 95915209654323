import { useMemo, useState } from "react";
import GridWrapper from "../../../components/GridWrapper";
import { IconButton } from "../../../components/buttons";
import { FormSelect } from "../../../components/forms";
import { components } from "react-select";

const ClickableMultiValueLabel = ({ onClick, selectProps: { onOptionClick }, ...props }) => {
    const handleClick = (e) => {
        if (e.preventDefault && typeof e.preventDefault === 'function')
            e.preventDefault();

        onOptionClick(props.data);
    }

    return <span className="has-pointer" onClick={handleClick}><components.MultiValueLabel {...props} /></span>

}

// Generic component that displays an IconButton alongside a FormSelect that, when pressed, opens the given ModalComponent
const FormSelectOpenModal = ({ IconModalComponent, modalProps: { onHide, ...modalProps }, icon, buttonVariant, onIconClickFunction, getOptionModalComponent, onOptionClickFunction, ...selectProps }) => {
    const [iconModalShown, setIconModalShown] = useState(false);
    const [fullIconModalProps, setFullIconModalProps] = useState(modalProps);

    const [optionModalShown, setOptionModalShown] = useState(false);
    const [fullOptionModalProps, setFullOptionModalProps] = useState(modalProps);

    // Run when the user clicks the icon to run the onIconClickFunction and open the modal with the result
    const openIconModal = () => {
        if (onIconClickFunction && typeof (onIconClickFunction) === 'function') {
            var possibleResult = onIconClickFunction();
            Promise.resolve(possibleResult).then(
                r => {
                    setIconModalShown(true);
                    setFullIconModalProps(prev => ({
                        ...prev,
                        ...r,
                    }));
                },
                error => { }
            );
        }
    };

    // Run when the user clicks a selected option to run the onOptionClickFunction and open the modal with the result
    const openOptionModal = (data) => {
        // Use the passed method to convert the option data into modal props
        const result = onOptionClickFunction(data);

        setOptionModalShown(true);
        setFullOptionModalProps(prev => ({
            ...prev,
            ...result,
        }));
    }

    const hideModals = () => {
        setIconModalShown(false)
        setOptionModalShown(false)
        setFullIconModalProps(modalProps);
        setFullOptionModalProps(modalProps);

        if (onHide && typeof onHide === 'function')
            onHide();
    };

    const OptionModalComponent = useMemo(() => {
        if (!getOptionModalComponent || typeof getOptionModalComponent !== 'function' || !optionModalShown)
            return null;

        return getOptionModalComponent(fullOptionModalProps);
    }, [fullOptionModalProps, getOptionModalComponent, optionModalShown]);

    return <GridWrapper gridTemplateColumns={"1fr auto"}>
        <IconModalComponent
            show={iconModalShown}
            onHide={hideModals}
            {...fullIconModalProps}
        />
        {OptionModalComponent && <OptionModalComponent 
            show={optionModalShown}
            onHide={hideModals}
            {...fullOptionModalProps}
        />}
        <FormSelect
            components={{
                ...components,
                MultiValueLabel: ClickableMultiValueLabel
            }}
            onOptionClick={openOptionModal}
            {...selectProps}
        />
        <IconButton
            className="my-auto"
            icon={icon}
            variant={buttonVariant}
            onClick={openIconModal}
        />
    </GridWrapper>;
}

export default FormSelectOpenModal;