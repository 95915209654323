import { wealthProApi } from '../../redux/api';

const portfolioViewApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["portfolioInvestments", "investmentsByVolatility", "performanceReturnsByVolatility", "performanceReturns"]
})
.injectEndpoints({
    endpoints: (builder) => ({
        getPortfolioView: builder.query({
            query: ({ masterAccountId }) => ({
                url: `api/valuations/portfolioviews/${masterAccountId}`,
                method: 'GET'
            }),
            providesTags: (result, error, arg) => {
                return ['portfolio'];
            }
        }),
        getPortfolioInvestmentsView: builder.query({
            query: ({ masterAccountId }) => ({
                url: `api/valuations/portfolioviews/investments/${masterAccountId}`,
                method: 'GET'
            }),
            providesTags: (result, error, arg) => {
                return ['portfolioInvestments'];
            }
        }),
        getPortfolioInvestmentsByVolatility: builder.query({
            query: ({ masterAccountId }) => ({
                url: `api/valuations/portfolioviews/investments/volatility/${masterAccountId}`,
                method: 'GET'
            }),
            providesTags: (result, error, arg) => {
                return ['investmentsByVolatility'];
            }
        }),
        getPortfolioHistoricalReturnsByVolatility: builder.query({
            query: ({ masterAccountId }) => ({
                url: `api/valuations/portfolioviews/performance/return/volatility/${masterAccountId}`,
                method: 'GET'
            }),
            providesTags: (result, error, arg) => {
                return ['performanceReturnsByVolatility'];
            }
        }),
        getPortfolioHistoricalReturns: builder.query({
            query: ({ masterAccountId }) => ({
                url: `api/valuations/portfolioviews/performance/return/${masterAccountId}`,
                method: 'GET'
            }),
            providesTags: (result, error, arg) => {
                return ['performanceReturns'];
            }
        }),
        getPortfolioHistoricalAnnualReturns: builder.query({
            query: ({ masterAccountId }) => ({
                url: `api/valuations/portfolioviews/performance/annualreturn/${masterAccountId}`,
                method: 'GET'
            }),
            providesTags: (result, error, arg) => {
                return ['performanceReturns'];
            }
        }),
    })
});

export const {
    useGetPortfolioViewQuery,
    useGetPortfolioInvestmentsViewQuery,
    useGetPortfolioInvestmentsByVolatilityQuery,
    useGetPortfolioHistoricalReturnsByVolatilityQuery,
    useGetPortfolioHistoricalReturnsQuery,
    useGetPortfolioHistoricalAnnualReturnsQuery
} = portfolioViewApiEndpoints;