import React from "react";
import usePortfolioInvestments from "../hooks/usePortfolioInvestments";
import useFundFactSheets from "../hooks/useFundFactSheets";
import PortfolioWrapper from "./PortfolioWrapper";
import Skeleton from "react-loading-skeleton";
import { NumericFormat } from "react-number-format";
import { useClientContext } from "../../../hooks/ClientContext";
import { ViewPdfModal } from "../../../components/modals";


const PortfolioInvestmentsView = () => {

    const { id } = useClientContext();


    const { data = null, error = false, isFetching = false, isLoading = false } = usePortfolioInvestments(id);
    const [{
        showPreview,
        hidePreview
    }, {
        pdfPreview,
        isGettingPdfPreview,
        shouldShowPdfPreview,
        getPdfPreviewError
    }] = useFundFactSheets();

    if(isLoading || isFetching){
        return(
            <PortfolioWrapper label="Loading Investments Summary...">
                <Skeleton />
            </PortfolioWrapper>
        );
    }

    if(error){
        return (
            <PortfolioWrapper label="Investments Summary">
                <div className="flex flex-col w-full text-center">
                    <p>Could not load Investments Summary</p>
                </div>
            </PortfolioWrapper>
        );
    }

    return( 
        <>
            <ViewPdfModal
                title="Fact Sheet Preview"
                data={pdfPreview}
                show={shouldShowPdfPreview}
                handleClose={hidePreview}
                isLoading={isGettingPdfPreview}
                error={getPdfPreviewError}
                backdrop
            />
            <PortfolioWrapper label="Investments Summary">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="w-30">Investment</th>
                                <th className="text-end">Value</th>
                                <th className="text-end">Allocation</th>
                                <th className="">Volatility</th>
                                <th className="">Asset Class</th>
                                <th className="">Monitoring</th>
                                <th className="">Opinion</th>
                            </tr>
                        </thead>
                        <tbody className="overflow-x-auto border-top-0">
                            {data != null && data.investments.length > 0 && data.investments.map((el, index) => (
                                <tr key={index}>
                                    {el.assetGroupId != null &&
                                        <td>
                                            <button className="btn btn-link p-0"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    showPreview(el.assetGroupId);
                                                }
                                            }>
                                                {el.investmentName}
                                            </button>
                                        </td>
                                    }
                                    {el.assetGroupId == null && <td>{el.investmentName}</td>}
                                    <td className="text-end tabular-nums"> 
                                        <NumericFormat
                                            value={el.value}
                                            thousandSeparator={true}
                                            decimalScale={0}
                                            displayType="text"
                                            prefix={"£"}
                                            /></td>
                                    <td className="text-end tabular-nums">{el.allocation}%</td>
                                    <td className="">{el.volatility}</td>
                                    <td className="">{el.assetClass}</td>
                                    <td className="">{el.monitoring}</td>
                                    <td className="">{el.opinion}</td>
                                </tr>
                            ))}    
                            {data == null || data.investments.length <= 0 && 
                                <tr>
                                <td>There are no investments to show.</td>
                                </tr>
                            }             
                        </tbody>
                    </table>
                </div>
            </PortfolioWrapper>
        </>
    );

}

export default PortfolioInvestmentsView;