import { useState } from "react";
import { Button } from "../../components";
import PageHeader, { PageHeaderTop } from "../../components/layout/PageHeader";
import { FiltersAppliedWarningMessage, PageSubHeader, ThemedIcon } from "../../components/utilities";
import { PageHeaderTitle } from "../../components/utilities/PageHeader";
import { FilterProvider, PortfolioTestResultDefaultFilters, useFilterContext } from "../../hooks/FilterContext";
import withProviders from "../../hooks/withProviders";
import { PortfolioTestResultsGrid } from "./components";
import { PortfolioTestResultFiltersModal } from "./components/PortfolioTestResultFilters";

const PortfolioTestResults = () => {
    const [isFiltersShown, setIsFiltersShown] = useState(false);

    const {
        clearFilter,
        isFiltersApplied
    } = useFilterContext();

    return <>
        <PortfolioTestResultFiltersModal
            handleClose={() => setIsFiltersShown(false)}
            show={isFiltersShown}
        />
        <PageHeader>
            <PageHeaderTop>
                <PageHeaderTitle>Portfolio Test Results Management</PageHeaderTitle>
            </PageHeaderTop>
        </PageHeader>
        <PageSubHeader>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    {isFiltersApplied && <>
                        <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                        <FiltersAppliedWarningMessage className="ms-2 text-muted">
                            Warning - Filters are currently being applied to your results.
                            <br />
                            <button className="anchor" onClick={clearFilter}>Click here</button> to clear your filters.
                        </FiltersAppliedWarningMessage>
                    </>}
                </div>
                <div className="d-flex flex-fill justify-content-end align-items-center flex-row">
                    <Button variant="primary" onClick={() => setIsFiltersShown(true)}>
                        Filter Test Results
                    </Button>
                </div>
            </div>
        </PageSubHeader>
        <PortfolioTestResultsGrid />
    </>;
}

export default withProviders([FilterProvider, { filterStore: "portfolioTestResults", defaultFilters: PortfolioTestResultDefaultFilters }])(PortfolioTestResults);