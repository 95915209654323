import ProjectManagement from "../features/projectmanagement";
import ServiceLevelAgreementsAdmin from "../features/servicelevelagreementadmin";
import PortfolioTestResults from "../features/portfoliotestresults";
import ManagementReportingRoutes from "./management.reporting.routes";
import ManagementReportRoutes from "./management.reports.routes";
import ManagementReviewsRoutes from "./management.reviews.routes";

const ManagementRoutes = [{
    path: "/management/*",
    roles: [],
    children: [
        ManagementReportRoutes,
        ManagementReviewsRoutes,
        ManagementReportingRoutes,
        { 
            path: 'projects', 
            roles: [],
            element: ProjectManagement
        },
        { 
            path: 'slas', 
            roles: [],
            element: ServiceLevelAgreementsAdmin
        },
        {
            path: 'portfolio-testing',
            roles: ['sys_admin_access', 'test_results_read'],
            element: PortfolioTestResults
        }
    ]
}];

export default ManagementRoutes;