import { useCallback } from "react";
import { usePersonalOutlookContext } from "../../../hooks/PersonalOutlookContext";
import toast from "react-hot-toast";
import { useDiaryContext } from "../hooks/DiaryContext";

const UserCalendarEventWrapper = ({ children, event, ...rest }) => {

    const { isEditable } = event || {};
    const { eventModal: { setProps } } = usePersonalOutlookContext();
    const { getEventById, monthIndex } = useDiaryContext();

    const mapToFormDataModel = useCallback((eventModel) => {
        return ({
            id: eventModel.id,
            organizer: eventModel.organizerUserId,
            attendees: eventModel.attendees.map(({ emailAddress }) => ({ label: emailAddress.name ?? emailAddress.address, value: emailAddress.address })) ?? [],
            description: eventModel.description ?? null,
            startDate: eventModel.startDate ?? null,
            duration: eventModel.duration ?? null,
            masterAccount: eventModel.masterAccountId && eventModel.masterAccountName ? { label: eventModel.masterAccountName, value: eventModel.masterAccountId } : null,
            project: eventModel.projectId && eventModel.projectName ? { label: eventModel.projectName, value: eventModel.projectId } : null,
            meetingType: eventModel.meetingType ?? null,
            meetingLocation: eventModel.meetingLocation ?? null,
            body: eventModel.body ?? null,
            onlineMeetingUrl: eventModel.onlineMeetingUrl
        })
    }, []);

    const onClick = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        setProps(prev => ({ show: true, isNew: false, isLoading: true, queryProps: { monthIndex }, initialId: event.id }));
        getEventById(event.id)
            .then(
                res => {
                    if (!res)
                        return;

                    // when an event is successfully loaded
                    // if the event loaded is not the same as the current event, don't update update the form
                    setProps((prev) => ({
                        ...prev,
                        initialData: res?.id !== prev?.initialId ? prev.initialData : mapToFormDataModel(res),
                        isLoading: false
                    }));
                },
                () => toast.error("Unable to get event.")
            );
    };

    return <div onClick={isEditable ? onClick : () => { }}>
        {children}
    </div>
};

export default UserCalendarEventWrapper;