import Moment from "react-moment";

const OverwriteLogs = ({overwriteLogs}) => {

    return (

        <div className="p-3">
            <div className="p-1 mb-2">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{width: "70%"}}>Reason</th>
                                        <th style={{width: "15%"}}>Date Overwritten</th>
                                        <th style={{width: "15%"}}>Overwritten By</th>
                                    </tr>
                                </thead>
                                <tbody className="overflow-x-auto border-top-0">
                                    {overwriteLogs != null && overwriteLogs.length > 0 && overwriteLogs.map((el, index) => (
                                        <tr key={index}>
                                            <td>{el.reviewOverwriteComment}</td>
                                            <td><Moment date={el.overwrittenDate} format="DD/MM/YYYY" /></td>
                                            <td>{el.overWrittenByDisplayName}</td>
                                        </tr>
                                    ))}    
                                    {overwriteLogs == null || overwriteLogs.length <= 0 && 
                                        <tr>
                                            <td>There are no logs to show.</td>
                                        </tr>
                                    }             
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) 

}

export default OverwriteLogs;