import _ from "lodash";
import { wealthProApi } from '../../redux/api';
import { socket } from "../../redux/socket";

const buildFetchProjectRemindersQuery = ({ 
    masterAccountId, 
    projectId,
    searchTerm = null,
    showCancelled = true,
    page = null,
    limit = null
}) => {
    let baseQuery = `/api/reminders/master-accounts/${masterAccountId}/projects/${projectId}/reminders?showCancelled=${showCancelled === true ? 'true' : 'false'}`;
    if (searchTerm) {
        var searchTermEncode = encodeURIComponent(searchTerm);
        baseQuery += `&searchTerm=${searchTermEncode}`;
    }
    if (page && limit) {
        baseQuery += `&page=${page}&limit=${limit}`
    }
    return baseQuery;
};

const projectRemindersApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["ProjectReminders"]
}).injectEndpoints({
    endpoints: (builder) => ({
        fetchProjectReminders: builder.query({
            query: (opts) => buildFetchProjectRemindersQuery(opts),
            serializeQueryArgs: ({ projectId, endpointName }) => `${endpointName}/${projectId}`,
            onCacheEntryAdded: async ({ projectId }, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
                try {
                    await cacheDataLoaded;
                    if (!projectId) return;
                    
                    socket.emit('subscribe-reminders', { room: `${projectId.replace(/-/g, '')}_reminders` });

                    socket.on('reminders.created', json => {
                        const reminder = JSON.parse(json);
                        updateCachedData(data => ({
                            ...data,
                            totalCount: data.totalCount + 1,
                            results: [reminder, ...data.results]
                        }));
                    });

                    socket.on('reminders.deleted', json => {
                        const reminder = JSON.parse(json);
                        updateCachedData(data => ({
                            ...data,
                            totalCount: data.totalCount - 1,
                            results: data.results.filter(x => x.id !== reminder.id.replace(/-/g, ''))
                        }));
                    });

                    socket.on('reminders.patched', json => {
                        const reminder = JSON.parse(json);
                        updateCachedData(data => {
                            let item = data.results.find(x => x.id === reminder.id);
                            item && Object.assign(item, reminder);
                        });
                    });
                }
                catch { }

                await cacheEntryRemoved;
                socket.emit('unsubscribe-reminders', { room: `${projectId.replace(/-/g, '')}_reminders` });
                socket.on('reminders.created')
                socket.on('reminders.deleted')
                socket.on('reminders.patched')
            },
            providesTags: ["ProjectReminders"]
        })
    })
});

export const {
    useLazyFetchProjectRemindersQuery
} = projectRemindersApiEndpoints;