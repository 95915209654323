import React, { useState, useEffect } from 'react';
import Review from "./Review";
import useClientReviewList from '../hooks/useClientReviewList';
import { useNavigate } from 'react-router-dom';
import { useClientContext } from "../../../hooks/ClientContext";
import { Spinner } from "../../../components/loaders";
import { AccordionList, AccordionHeader, AccordionListItem, AccordionBody, CenterPage, ThemedParagraph } from "../../../components/utilities";
import styled from 'styled-components';
import { OverwriteReviewProvider } from '../../revieweditmodal/hooks/OverwriteReviewContext';

const StyledWrapper = styled.div``;

const StyledNameBadge = styled.div``;

const ReviewList = ({ serviceTermsUrl = "serviceterms" }) => {
    const { id } = useClientContext();
    const { data: reviewList, isLoading} = useClientReviewList(id);
    const [activeKeys, setActiveKeys] = useState([]);
    const navigate = useNavigate();

    const navigateToServiceTerms = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        navigate(`../${serviceTermsUrl}`, { relative: 'path' });
    };

    useEffect(() => {
        if(reviewList?.length > 0){
            setActiveKeys([reviewList[0].value]);
        }
    }, [reviewList]);

    if (isLoading) {
        return(
            <CenterPage>
                <Spinner />
            </CenterPage>
        );
    }

    if (!isLoading && (!reviewList || reviewList.length === 0)) {
        return (
            <ThemedParagraph variant="muted">
                Sorry! But, there are no reviews available for this account. This account is likely missing configured <a href={`../${serviceTermsUrl}`} onClick={navigateToServiceTerms}>service terms.</a>
            </ThemedParagraph>
        );
    }

    return (
        <React.Fragment>
            <div className='row'>
                <div className='col'>
                    <AccordionList 
                        activeKey={activeKeys} 
                        defaultActiveKey={null}
                        onSelect={(eventKeys, _) => setActiveKeys(eventKeys)}
                        gap={1} 
                        alwaysOpen>
                        {reviewList.map((el) => (
                            <AccordionListItem key={el.value} eventKey={el.value}>
                                    <AccordionHeader>
                                        <StyledWrapper>
                                            <StyledNameBadge>{el.label}</StyledNameBadge>
                                        </StyledWrapper>
                                    </AccordionHeader>
                                    <AccordionBody>
                                        <OverwriteReviewProvider>
                                            {activeKeys.includes(el.value) &&
                                                <Review key={el.value} masterAccountId={id} reviewId={el.value} />
                                            }
                                        </OverwriteReviewProvider>
                                    </AccordionBody>
                            </AccordionListItem>
                        ))}
                    </AccordionList>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ReviewList;