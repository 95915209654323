import { useClientContext } from "../../../hooks/ClientContext";
import { useFetchPortfolioChangesQuery } from "../../../services/headofficeadmin";

const usePortfolioChanges = () => {

    const { id: masterAccountId } = useClientContext();
    const { data: portfolioChanges, isLoading: isLoadingPortfolioChanges } = useFetchPortfolioChangesQuery({ masterAccountId })
    
    return {
        isLoadingPortfolioChanges,
        portfolioChanges
    };
}

export default usePortfolioChanges;

