import { applyPatch } from "fast-json-patch";
import { wealthProApi } from "../../redux/api";

const icAssetGroupApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["assetGroups"]
}).injectEndpoints({
    endpoints: (build) => ({
        countAssetGroupsForAnalysisGrid: build.query({
            query: ({ volatility, filters }) => ({
                url: `api/assets/assetGroup/analysis/count`,
                method: 'POST',
                body: { volatility, filters }
            }),
            providesTags: ['assetGroups']
        }),
        fetchAssetGroupsForAnalysisGrid: build.query({
            query: ({ fetchType, volatility, groupBySector, filters, sort }) => ({
                url: `api/assets/assetGroup/analysis`,
                method: 'POST',
                body: { fetchType, volatility, groupBySector, filters, sort }
            }),
            providesTags: ['assetGroups']
        }),
        fetchAssetGroupsForManagementGrid: build.query({
            query: ({ filters, sort }) => ({
                url: `api/assets/assetGroup/management`,
                method: 'POST',
                body: { filters, sort }
            }),
            providesTags: ['assetGroups']
        }),
        createAssetGroup: build.mutation({
            query: () => ({
                url: `api/assets/assetGroup`,
                method: 'POST'
            }),
            invalidatesTags: ['assetGroups']
        }),
        fetchAssetGroup: build.query({
            query: ({ assetGroupId }) => `api/assets/assetGroup/${assetGroupId}`
        }),
        fetchFactSheetPdf: build.query({
            query: ({ assetGroupId, draft }) => `api/assets/fundfactsheet/${assetGroupId}${draft != null ? `?draft=${draft}` : ""}`
        }),
        patchAssetGroup: build.mutation({
            query: ({ assetGroupId, operations }) => ({
                url: `api/assets/assetGroup/${assetGroupId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ assetGroupId, operations }, { dispatch, queryFulfilled }) {
                try {
                    const patchResult = dispatch(wealthProApi.util.updateQueryData("fetchAssetGroup", { assetGroupId }, (draft) => {
                        applyPatch(draft, operations);
                    }));

                    try {
                        await queryFulfilled;
                    } catch (error) {
                        console.error("Failed to patch asset in API", error);
                        patchResult.undo();
                    }
                } catch (error) {
                    console.error("Failed to update existing data", error);
                }
            }
        }),
        createAssetMapping: build.mutation({
            query: ({ assetGroupId, assetId }) => ({
                url: `api/assets/assetGroup/${assetGroupId}/mapping`,
                method: 'POST',
                body: { assetId }
            }),
            async onQueryStarted({ assetGroupId, assetId }, { dispatch, queryFulfilled }) {
                try {
                    // Update pessimistically for Created By/Date
                    const { data } = await queryFulfilled;

                    const createdMap = data == null
                        ? {
                            assetGroupId,
                            assetId,
                            asset: {
                                sedol: "",
                                assetName: "Code not recognised"
                            },
                            isPrimaryMap: true
                        }
                        : {
                            ...data,
                            isPrimaryMap: true
                        };

                    dispatch(wealthProApi.util.updateQueryData("fetchShareClassMappings", { assetGroupId }, (draft) => {
                        // If the existing list does not contain the current "Primary" (Asset) Mapping, add it to the beginning of the list
                        if (draft.length === 0 || !draft[0].isPrimaryMap)
                            return [
                                createdMap,
                                ...draft
                            ];

                        return [createdMap, ...draft.slice(1)];
                    }));
                } catch (error) {
                    console.error("Failed to update existing data", error);

                    dispatch(wealthProApi.util.updateQueryData("fetchShareClassMappings", { assetGroupId }, (draft) => [
                        {
                            assetGroupId,
                            asset: {
                                sedol: draft?.[0]?.asset?.sedol ?? "",
                                assetName: "Code not recognised"
                            }
                        },
                        ...draft
                    ]));
                }
            }
        }),
        deleteAssetMapping: build.mutation({
            query: ({ assetGroupId }) => ({
                url: `api/assets/assetGroup/${assetGroupId}/mapping`,
                method: 'DELETE'
            }),
            async onQueryStarted({ assetGroupId }, { dispatch, queryFulfilled }) {
                try {
                    const deleteMappingAction = dispatch(wealthProApi.util.updateQueryData("fetchShareClassMappings", { assetGroupId }, (draft) => {
                        return draft.slice(1);
                    }))

                    try {
                        await queryFulfilled
                    } catch (err) {
                        console.error("Error deleting mapping");
                        deleteMappingAction.undo();
                    }
                } catch (err) {
                    console.error("Error updating cache", err);
                }
            }
        }),
        // Need an endpoint specifically for updating volatility profile, to update the modified by/date pessimistically
        patchAssetGroupVolatilityProfile: build.mutation({
            query: ({ assetGroupId, operations }) => ({
                url: `api/assets/assetGroup/${assetGroupId}/volatility`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ assetGroupId, operations }, { dispatch, queryFulfilled }) {
                try {
                    const patchResult = dispatch(wealthProApi.util.updateQueryData("fetchAssetGroup", { assetGroupId }, (draft) => {
                        applyPatch(draft, operations);
                    }));

                    try {
                        const { data } = await queryFulfilled;
                        // Update modified by/date
                        dispatch(wealthProApi.util.updateQueryData("fetchAssetGroup", { assetGroupId }, (draft) => {
                            draft.volatilityProfileModifiedBy = data.volatilityProfileModifiedBy;
                            draft.volatilityProfileModifiedDate = data.volatilityProfileModifiedDate;
                        }));
                    } catch (error) {
                        console.error("Failed to patch asset in API", error);
                        patchResult.undo();
                    }
                } catch (error) {
                    console.error("Failed to update existing data", error);
                }
            }
        }),
        // Need an endpoint specifically for updating commentaries, to update the modified by/date pessimistically
        patchAssetGroupCommentary: build.mutation({
            query: ({ assetGroupId, commentaryType, newCommentary }) => ({
                url: `api/assets/assetGroup/${assetGroupId}/commentary`,
                method: 'PATCH',
                body: { type: commentaryType, newCommentary }
            }),
            async onQueryStarted({ assetGroupId, commentaryTextField, newCommentary }, { dispatch, queryFulfilled }) {
                try {
                    const patchResult = dispatch(wealthProApi.util.updateQueryData("fetchAssetGroup", { assetGroupId }, (draft) => {
                        draft[commentaryTextField] = newCommentary;
                        draft.controlStatus = 0; // Draft
                    }));

                    try {
                        const { data } = await queryFulfilled;

                        // Update cache with full new data
                        dispatch(wealthProApi.util.updateQueryData("fetchAssetGroup", { assetGroupId }, () => data));
                    } catch (error) {
                        console.error("Failed to patch asset in API", error);
                        patchResult.undo();
                    }
                } catch (error) {
                    console.error("Failed to update existing data", error);
                }
            }
        }),
        approveCommentaries: build.mutation({
            query: ({ assetGroupId }) => ({
                url: `api/assets/assetGroup/${assetGroupId}/approve`,
                method: 'POST'
            }),
            async onQueryStarted({ assetGroupId }, { dispatch, queryFulfilled }) {
                try {
                    const updateControlStatus = dispatch(wealthProApi.util.updateQueryData("fetchAssetGroup", { assetGroupId }, (draft) => {
                        draft.controlStatus = 2; // Approved
                    }));

                    try {
                        const { data } = await queryFulfilled;

                        // Update cache with full new data
                        dispatch(wealthProApi.util.updateQueryData("fetchAssetGroup", { assetGroupId }, () => data));
                    } catch (error) {
                        console.error("Failed to Approve the commentaries on this fund", error);
                        updateControlStatus.undo();
                    }

                } catch (error) {
                    console.error("Failed to Approve the commentaries on this fund", error);
                }
            }
        }),
        publishCommentaries: build.mutation({
            query: ({ assetGroupId }) => ({
                url: `api/assets/assetGroup/${assetGroupId}/publish`,
                method: 'POST'
            }),
            async onQueryStarted({ assetGroupId }, { dispatch, queryFulfilled }) {
                try {
                    const updateControlStatus = dispatch(wealthProApi.util.updateQueryData("fetchAssetGroup", { assetGroupId }, (draft) => {
                        draft.controlStatus = 3; // Published
                    }));

                    try {
                        const { data } = await queryFulfilled;

                        // Update cache with full new data
                        dispatch(wealthProApi.util.updateQueryData("fetchAssetGroup", { assetGroupId }, () => data));
                    } catch (error) {
                        console.error("Failed to Approve the commentaries on this fund", error);
                        updateControlStatus.undo();
                    }

                } catch (error) {
                    console.error("Failed to Approve the commentaries on this fund", error);
                }
            }
        }),
        removeAssetMonitoring: build.mutation({
            query: ({ assetGroupId }) => ({
                url: `api/assets/assetGroup/${assetGroupId}`,
                method: 'DELETE'
            })
        })
    })
});

export const {
    useLazyCountAssetGroupsForAnalysisGridQuery,
    useLazyFetchAssetGroupsForAnalysisGridQuery,
    useLazyFetchAssetGroupsForManagementGridQuery,
    useCreateAssetGroupMutation,
    useLazyFetchAssetGroupQuery,
    useLazyFetchFactSheetPdfQuery,
    usePatchAssetGroupMutation,
    useCreateAssetMappingMutation,
    useDeleteAssetMappingMutation,
    usePatchAssetGroupVolatilityProfileMutation,
    usePatchAssetGroupCommentaryMutation,
    useApproveCommentariesMutation,
    usePublishCommentariesMutation,
    useRemoveAssetMonitoringMutation
} = icAssetGroupApiEndpoints;