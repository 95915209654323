import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import "../../../services/recommendations";
import { useApplyBizSheetCostsMutation, useCreateInstructionMutation, useDeleteInstructionMutation, useFinaliseBizSheetMutation, useLazyFetchBizSheetQuery, useLazyFetchDesignationsQuery, useLazyFetchInstructionQuery, useLazyGetPdfQuery, useMakeBizSheetDraftMutation, usePatchInstructionMutation } from "../../../services/recommendations";

const BizSheetContext = React.createContext();

export const useBizSheet = () => {
    return useContext(BizSheetContext);
}

export const BizSheetProvider = ({ children }) => {
    const [selectedSheetId, setSelectedSheetId] = useState();

    const [fetchSheet, { data: sheet, error: sheetError, isError: sheetIsError, isFetching: sheetIsLoading, isUninitialised: sheetIsUninitialised }, { lastArg: { bizSheetId: fetchedSheetId } }] = useLazyFetchBizSheetQuery();
    const [fetchInstruction, { data: instruction, error: instructionError, isError: instructionIsError, isLoading: instructionIsLoading, isUninitialised: instructionIsUninitialised }] = useLazyFetchInstructionQuery();

    const [patchInstruction, { error: patchInstructionError, isError: patchInstructionIsError, isLoading: patchInstructionIsLoading, isUninitialised: patchInstructionIsUninitialised }] = usePatchInstructionMutation();

    const [createInstructionTrigger, { error: createInstructionError, isError: createInstructionIsError, isLoading: createInstructionIsLoading, isUninitialised: createInstructionIsUninitialised }] = useCreateInstructionMutation();

    const createInstruction = useCallback((typeId) =>
        createInstructionTrigger({ sheetId: selectedSheetId, newId: v4(), typeId }).unwrap()
            .then(res => {
                setOpenInstructions(open => [...open, res.id]);
            }),
        [createInstructionTrigger, selectedSheetId]);

    const [deleteInstruction, { error: deleteInstructionError, isError: deleteInstructionIsError, isLoading: deleteInstructionIsLoading, isUninitialised: deleteInstructionIsUninitialised }] = useDeleteInstructionMutation();

    const [fetchDesignations, { data: designations, isLoading: designationsIsLoading }] = useLazyFetchDesignationsQuery();
    const [applyCosts, { isLoading: isApplyingCosts }] = useApplyBizSheetCostsMutation();

    const apply = () => applyCosts({ bizSheetId: selectedSheetId });

    useEffect(() => {
        if (selectedSheetId) {
            fetchSheet({ bizSheetId: selectedSheetId });
        }
    }, [fetchSheet, selectedSheetId]);

    useEffect(() => {
        if (sheet?.masterAccountId) {
            fetchDesignations({ masterAccountId: sheet?.masterAccountId, listType: "select" })
        }
    }, [fetchDesignations, sheet]);

    const [openInstructions, setOpenInstructions] = useState([]);
    const shouldRenderInstruction = useCallback(instructionId =>
        openInstructions.includes(instructionId.replace(/-/g, '')), [openInstructions])

    const refetchSheet = () => {
        fetchSheet({ bizSheetId: selectedSheetId });
    }

    // PDF display handling
    const [getPdfTrigger, { isLoading: isGettingPdf, isError: getPdfIsError, error: getPdfError }] = useLazyGetPdfQuery();
    const [pdfData, setPdfData] = useState(null);
    const [shouldShowPdf, setShouldShowPdf] = useState(false);

    const showPdf = () => {
        setShouldShowPdf(true);
        return getPdfTrigger({ bizSheetId: selectedSheetId, instructionIds: bizSheetInstructionIds }, false)
            .unwrap()
            .then(res => {
                if (res.isValid)
                    return setPdfData(res.pdfData);

                hidePdf();
                alert("This business sheet has errors. Please check all Instructions and Investment Flows before generating the PDF or making the report compliant.");
            });
    };

    const hidePdf = () => {
        setPdfData(null);
        setShouldShowPdf(false);
    };

    // Business sheet status handling
    // Make biz sheet read-only, set status to Final Draft, and validate instructions
    const [finaliseBizSheetTrigger, { isLoading: isFinalising, isError: finaliseIsError, error: finaliseError }] = useFinaliseBizSheetMutation();

    const bizSheetInstructionIds = sheet?.instructions?.map(i => i.id)
    const saveDraft = useCallback(() =>
        finaliseBizSheetTrigger({ bizSheetId: selectedSheetId, instructionIds: bizSheetInstructionIds }).unwrap(), [bizSheetInstructionIds, finaliseBizSheetTrigger, selectedSheetId]);

    // Make biz sheet editable and set status to Draft
    const [setAsDraftTrigger, { isLoading: isSettingAsDraft, isError: setAsDraftIsError, error: setAsDraftError }] = useMakeBizSheetDraftMutation();
    const setAsDraft = useCallback(() =>
        setAsDraftTrigger({ bizSheetId: selectedSheetId }).unwrap(),
        [selectedSheetId, setAsDraftTrigger]);

    const isPatchingStatus = useMemo(() => isFinalising || isSettingAsDraft, [isFinalising, isSettingAsDraft]);
    const patchStatusIsError = useMemo(() => finaliseIsError || setAsDraftIsError, [finaliseIsError, setAsDraftIsError]);
    const patchStatusError = useMemo(() => finaliseError || setAsDraftError, [finaliseError, setAsDraftError]);

    const makeReadonly = useCallback(() => sheet?.isReadOnly
        ? setAsDraft()
        : saveDraft(), [saveDraft, setAsDraft, sheet?.isReadOnly]);

    // TODO: Remove temporary send to admin button logic
    const [sheetHasErrors, setSheetHasErrors] = useState(null);

    // Reset the Send to Admin button when selected sheet changes
    useEffect(() => {
        setSheetHasErrors(null);
    }, [selectedSheetId]);

    // Context returns a tuple in the form [ triggers, objects ] 
    return <BizSheetContext.Provider value={[{
        refetchSheet,
        setSelectedSheetId,
        applyCosts: apply,
        createInstruction,
        fetchInstruction,
        patchInstruction,
        deleteInstruction,
        showPdf,
        hidePdf,
        makeReadonly
    },
    {
        selectedSheetId,
        fetchedSheetId,
        sheet,
        sheetError,
        sheetIsError,
        sheetIsLoading,
        sheetIsUninitialised,
        isApplyingCosts,
        createInstructionError,
        createInstructionIsError,
        createInstructionIsLoading,
        createInstructionIsUninitialised,
        instruction,
        instructionIsError,
        instructionError,
        instructionIsLoading,
        instructionIsUninitialised,
        patchInstructionError,
        patchInstructionIsError,
        patchInstructionIsLoading,
        patchInstructionIsUninitialised,
        deleteInstructionError,
        deleteInstructionIsError,
        deleteInstructionIsLoading,
        deleteInstructionIsUninitialised,
        designations,
        designationsIsLoading,
        pdfData,
        isGettingPdf,
        shouldShowPdf,
        getPdfIsError,
        getPdfError,
        isPatchingStatus,
        patchStatusIsError,
        patchStatusError
    }, {
        openInstructions,
        setOpenInstructions,
        shouldRenderInstruction,
        sheetHasErrors,
        setSheetHasErrors
    }]}>
        {children}
    </BizSheetContext.Provider>
}